<template>
    <div class="contain">
        <div style="width:100%;display: flex;justify-content: center;background-color: #F3F3F3;">
            <div style="width:7.1771rem;font-size: .0938rem;" class="opp">





                <div style="width: 7.2865rem;font-size: .1042rem; text-align: left;top: .4167rem;z-index: 99;transform: translateY(-0.1302rem);" >
       

                    <el-breadcrumb separator-class="el-icon-arrow-right" style="font-size: .0833rem;">
                        <el-breadcrumb-item :to="{ path: '/PageHome' }" >首页</el-breadcrumb-item>
                        <el-breadcrumb-item
                            :to="{ path: '/casePresentation', query: { tab: 'first' }}">案例展示</el-breadcrumb-item>
                    </el-breadcrumb>
                
                </div>
                
                <t-space style="width: 100%;margin-top: .0521rem;" direction="vertical">
                    <t-tabs theme="card" v-model="activeName" type="border-card" @change="changeTab">
                        <t-tab-panel style="height:auto;margin-bottom: .0938rem;" value="first" label="交通雷达">

                            <div
                                style="width: 100%; display: flex; justify-content: center; align-items: center;margin-top: .1563rem;">
                                <div @click="handleClick3" @mouseover="chaImg" @mouseout="chaoutImg"
                                style="width: 2.0833rem; height: 1.8229rem;    position: relative; overflow: hidden;">

                                <img src="../assets/机场高速改.webp" alt=""
                                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                <p
                                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                    广州机场高速</p>
                            </div>

                                <div @click="handleClick2" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 4.3021rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                    <img src="../assets/2云南.webp" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        云南保山高速</p>
                                </div>
                            </div>

                            <div
                                style="width: 100%;margin-top: .0521rem; display: flex; justify-content: center; align-items: center;">
                                
                                <div @click="handleClick1" @mouseover="chaImg" @mouseout="chaoutImg"
                                style="width: 2.0833rem; height: 1.8229rem; position: relative;overflow: hidden;">
                                <img src="../assets/第三个.webp" alt="" style="float: left;width: 100%;height: 100%;">
                                <p
                                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                    广州从埔高速</p>
                            </div>


                               

                                <!-- <div @click="handleClick5" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                    <img src="../assets/九龙大道 改.webp" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        </p>
                                </div> -->

                                <div @click="handleClick5" @mouseover="chaImg" @mouseout="chaoutImg"
                                style="width: 4.3021rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                <img src="../assets/九龙大道 改.jpg" alt=""
                                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                <p
                                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                    知识城九龙大道毫米波雷达</p>
                            </div>

                                 <!-- <div @click="handleClick4" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                    <img src="../assets/茂湛高速.jpg" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        茂湛高速</p>
                                </div> -->
                            </div>
                        </t-tab-panel>


                        <t-tab-panel value="six" style="height:auto;margin-bottom: .0938rem;" label="雷达通信模组">

                            <div
                                style="width: 100%;margin-top: .0521rem; display: flex; justify-content: center; align-items: center;">
                                <div @click="handleClick6" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;    position: relative; overflow: hidden;">

                                    <img src="../assets/物联自组网雷达感应模块采购项目 改.webp" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        物联自组网雷达感应模块</p>
                                </div>


                                <div @click="handleClick7" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                    <img src="../assets/中科4g改.jpg" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        中科4G超级灯</p>
                                </div>

                                <div @click="handleClick88" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                    <img src="../assets/3雷达.webp" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        美的433MHz网络模块组件</p>
                                </div>
                            </div>
                        </t-tab-panel>

                        <!-- 
                            第三个

                         -->

                        <t-tab-panel value="seven" style="height: auto ; margin-bottom: .0938rem;" label="智能收费亭">

                            <div>

                                <div
                                    style="width: 100%; display: flex; justify-content: center; align-items: center;margin-top: .1563rem;">
                                    <div @click="handleClick9" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 2.0833rem; height:  1.8229rem;    position: relative;overflow: hidden;">
                                        <img src="../assets/1智能收费亭.webp" alt="" style="float: left;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">
                                            贵州余安高速</p>
                                    </div>


                                    <div @click="handleClick10" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width:  2.0833rem; height:1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                        <img src="../assets/智能收费亭290.webp" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        
                                            <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                            黑龙江高速公路收费站改造</p>
                                    </div>


                                    <div @click="handleClick15" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">
    
                                    <img src="../assets/gaitu2.jpg" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        四川成宜高速</p>
                                </div>

                                </div>




                                <div
                                    style="width: 100%;margin-top: .0521rem;margin-top: .1563rem; display: flex; justify-content: center; align-items: center;">



                                    <div @click="handleClick11" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 2.0833rem; height: 1.8229rem;    position: relative; overflow: hidden;">

                                        <img src="../assets/gaitu1.jpg" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                            广东仁新高速</p>
                                    </div>


                                    <!---->
                              

                                <!-- <div @click="handleClick15" @mouseover="chaImg" @mouseout="chaoutImg"
                                style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                <img src="../assets/gaitu2.jpg" alt=""
                                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                <p
                                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                    四川成宜高速</p>
                            </div> -->

                            
                            <div @click="handleClick14" @mouseover="chaImg" @mouseout="chaoutImg"
                            style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                            <img src="../assets/gaitu4.jpg" alt=""
                                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                            <p
                                style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                贵州绥遵高速</p>
                        </div>


                                    <div @click="handleClick13" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                        <img src="../assets/gaitu3.jpg" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                            广东广三高速</p>
                                    </div>




                                </div>

                                <!-- <div
                                    style="width: 100%;margin-top:.0521rem; display: flex; justify-content: center; align-items: center;">


                                    <div @click="handleClick12" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                    <img src="../assets/哈尔滨.webp" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        哈尔滨长岭湖收费站</p>
                                   </div> 
                                  


                                    <div @click="handleClick16" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                        <img src="../assets/安徽1.webp" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                            德上高速</p>
                                    </div>
                                </div> -->







                            </div>





                        </t-tab-panel>






                        <t-tab-panel value="eight" style="height:auto;margin-bottom: .0938rem;" label="交通诱导系列">

                            <div
                                style="width: 100%;margin-top: .0521rem;margin-top: .1563rem; display: flex; justify-content: center; align-items: center;">



                                <div @click="handleClick17" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;    position: relative; overflow: hidden;">

                                    <img src="../assets/1交通.webp" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        云南山区高速行车安全智能诱导</p>
                                </div>


                               
                                <div @click="handleClick19" @mouseover="chaImg" @mouseout="chaoutImg"
                                style="width: 4.3021rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                <img src="../assets/3交通.png" alt=""
                                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                <p
                                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                    安徽北沿江高速</p>
                            </div>

                          

                              
                            </div>





                            <div
                                style="width: 100%;margin-top:.0521rem; display: flex; justify-content: center; align-items: center;">


                                <div @click="handleClick18" @mouseover="chaImg" @mouseout="chaoutImg"
                                style="width: 2.0833rem; height: 1.8229rem;    position: relative; overflow: hidden;">

                                <img src="../assets/云南1.webp" alt=""
                                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                <p
                                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                    云南广那高速</p>
                            </div>
                            


                                <div @click="handleClick21" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                    <img src="../assets/四川案例.webp" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        四川乐西高速
                                    </p>
                                </div>

                                <!-- <div @click="handleClick22" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                    <img src="../assets/案例雾灯.webp" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        昆明高速公路养护路段
                                    </p>
                                </div> -->

                                <div @click="handleClick20" @mouseover="chaImg" @mouseout="chaoutImg"
                                style="width: 2.0833rem; height: 1.8229rem; margin-left: .1354rem;   position: relative; overflow: hidden;">

                                <img src="../assets/4交通.webp" alt=""
                                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                <p
                                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                    清远高速公路隐患路段</p>
                            </div>
                            </div>
                        </t-tab-panel>



                        <t-tab-panel value="third" style="height:auto;margin-bottom: .026rem;" label="可变情报板系列">


                            <!-- <div style="width: 100%;" v-if="currentPage === 1" class="page-content"> -->


                                <div
                                    style="width: 100%; display: flex; justify-content: center; align-items: center;margin-top: .1563rem;">
                                    <div @click="handleClick23" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 2.0833rem; height: 1.8229rem;    position: relative;overflow: hidden;">
                                        <img src="../assets/广平高速(1).jpg" alt="" style="float: left;width: 100%;height: 1005;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">
                                            广平高速</p>
                                    </div>


                                    <!-- <div @click="handleClick24" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 4.3021rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                        <img src="../assets/501.png" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                            广东省2019年取消省界收费站项目ETC车道LED指示标志设备采购</p>
                                    </div> -->


                                    <div @click="handleClick28" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;margin-left: .1354rem;    position: relative; overflow: hidden;">

                                    <img src="../assets/6可变情报板.webp" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        甘肃静天高速</p>
                                </div>

                                <div @click="handleClick34" @mouseover="chaImg" @mouseout="chaoutImg"
                                style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;   position: relative; overflow: hidden;">

                                <img src="../assets/12可变情报板.webp" alt=""
                                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                <p
                                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                    广东惠盐高速</p>
                            </div>
                                </div>




                                <div
                                    style="width: 100%;margin-top: .0521rem; display: flex; justify-content: center; align-items: center;">



                                    <div @click="handleClick25" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 2.0833rem; height: 1.8229rem;    position: relative; overflow: hidden;">

                                        <img src="../assets/德会高速.jpg" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                            四川德会高速</p>
                                    </div>


                                    <div @click="handleClick32" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                    <img src="../assets/10可变情报板.webp" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        贵州瓮开高速</p>
                                </div>


                                <div @click="handleClick39" @mouseover="chaImg" @mouseout="chaoutImg"
                                style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                <img src="../assets/17可变情报板.webp" alt=""
                                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                <p
                                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                    广东中交广连高速</p>
                            </div>


                                  

                                  
                                </div>





                                <!-- <div
                                    style="width: 100%;margin-top: 10px; display: flex; justify-content: center; align-items: center;">



                                    <div @click="handleClick28" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 400px; height: 350px;    position: relative; overflow: hidden;">

                                        <img src="../assets/6可变情报板.png" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                            S25静宁至天水高速公路静宁至庄浪段建设项目JZJD1机电工程</p>
                                    </div>

                                    <div @click="handleClick34" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 400px; height: 350px;  margin-left: 26px;   position: relative; overflow: hidden;">

                                    <img src="../assets/12可变情报板.png" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                        惠盐高速公路深圳段改扩建工程机电工程采购</p>
                                </div>
                                    


                                

                                    <div @click="handleClick30" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 400px; height: 350px;  margin-left: 26px;  position: relative; overflow: hidden;">

                                        <img src="../assets/501.png" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                            三条市管高速智能交通管控系统项目系统软硬件与系统集成一标段项目</p>
                                    </div>
                                </div> -->










                            <!-- </div> -->









<!-- 
                            <div style="width: 100%;" v-if="currentPage === 2" class="page-content"> -->
                                <!-- <div>




                                    <div
                                        style="width: 100%;margin-top: 10px; display: flex; justify-content: center; align-items: center;">



                                        <div @click="handleClick31" @mouseover="chaImg" @mouseout="chaoutImg"
                                            style="width: 400px; height: 350px;    position: relative; overflow: hidden;">

                                            <img src="../assets/501.png" alt=""
                                                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                            <p
                                                style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                                黑龙江2021年全省高速公路品质提升工程收费站站区路面改造项目A6标段项目</p>
                                        </div>


                                      

                                        <div @click="handleClick26" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 400px; height: 350px;  margin-left: 26px;  position: relative; overflow: hidden;">

                                        <img src="../assets/501.png" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                            贵州省正安至习水高速公路ppp项目</p>
                                    </div>


                                        <div @click="handleClick33" @mouseover="chaImg" @mouseout="chaoutImg"
                                            style="width: 400px; height: 350px;  margin-left: 26px;  position: relative; overflow: hidden;">

                                            <img src="../assets/501.png" alt=""
                                                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                            <p
                                                style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                                宜威高速公路机电设备（监控系统设备）集中采购可变标志设备</p>
                                        </div>
                                    </div>




                                    <div
                                        style="width: 100%;margin-top: 10px; display: flex; justify-content: center; align-items: center;">

                                        <div @click="handleClick29" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 400px; height: 350px;   position: relative; overflow: hidden;">

                                        <img src="../assets/501.png" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                            银百高速(G69)甜永段TYJD3项目经理部</p>
                                    </div>

                                     


                                        <div @click="handleClick35" @mouseover="chaImg" @mouseout="chaoutImg"
                                            style="width: 400px; height: 350px;  margin-left: 26px;  position: relative; overflow: hidden;">

                                            <img src="../assets/501.png" alt=""
                                                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                            <p
                                                style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                                鹤庆至剑川至兰坪高速公路项目沙溪支线收费等系统设备采购项目</p>
                                        </div>

                                        <div @click="handleClick36" @mouseover="chaImg" @mouseout="chaoutImg"
                                            style="width: 400px; height: 350px;  margin-left: 26px;  position: relative; overflow: hidden;">

                                            <img src="../assets/501.png" alt=""
                                                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                            <p
                                                style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                                G244线打扮梁（陕甘界）至庆城段公路工程机电工程施工总承包项目</p>
                                        </div>
                                    </div>





                                    <div
                                        style="width: 100%;margin-top: 10px; display: flex; justify-content: center; align-items: center;">



                                        <div @click="handleClick37" @mouseover="chaImg" @mouseout="chaoutImg"
                                            style="width: 400px; height: 350px;    position: relative; overflow: hidden;">

                                            <img src="../assets/501.png" alt=""
                                                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                            <p
                                                style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                                南沙至中山高速公路项目机电工程施工（JD01合同段）</p>
                                        </div>


                                        <div @click="handleClick38" @mouseover="chaImg" @mouseout="chaoutImg"
                                            style="width: 400px; height: 350px;  margin-left: 26px;  position: relative; overflow: hidden;">

                                            <img src="../assets/501.png" alt=""
                                                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                            <p
                                                style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                                广州市从化至黄埔高速公路一期工程机电工程（SG09合同段）</p>
                                        </div>

                                       

                                        <div @click="handleClick27" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 400px; height: 350px;  margin-left: 26px;  position: relative; overflow: hidden;">

                                        <img src="../assets/501.png" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                            青海省取消高速公路省界收费站工程设计施工总承包</p>
                                    </div>
                                    </div> -->

                                <!-- </div> -->






                                <!-- 大的 div 结束标签 -->
                           
                            <!-- <div style="display:flex ;justify-content: center;margin-top: 20px;">


                                <i class="el-icon-arrow-left" @click="prevPage"
                                    style="color:#4e4949;width: 40px;height: 40px;text-align: center;line-height: 40px;font-size: 25px;"></i>
                                <button @click="prevPage"></button>

                                <ul class="pagination">
                                    <li v-for="page in pages" :key="page" :class="{ active: currentPage === page }"
                                        @click="changePage(page)">
                                        {{ page }}
                                    </li>
                                </ul>

                                <i class="el-icon-arrow-right" @click="nextPage"
                                    style="color:#4e4949;width: 40px;height: 40px;;text-align: center;line-height: 40px;font-size: 25px;"></i>
                                <button @click="nextPage"></button> -->

                            <!-- </div> -->





                        </t-tab-panel>


                        <!--  -->


                        <t-tab-panel value="nine" style="height:auto;margin-bottom: .0938rem;" label="收费车道设备系列">



                           

                                <div
                                    style="width: 100%; display: flex; justify-content: center; align-items: center;margin-top: .1563rem;">
                                 

                                    <div @click="handleClick51" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;   position: relative; overflow: hidden;">

                                    <img src="../assets/12收费车道.webp" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        广西天巴高速</p>
                                </div>


                                    <div @click="handleClick41" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 4.3021rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                        <img src="../assets/2收费车道.webp" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                            湖南大戛高速</p>
                                    </div>
                                </div>




                                <div
                                    style="width: 100%;margin-top: .0521rem; display: flex; justify-content: center; align-items: center;">



                             


                                    <div @click="handleClick49" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;    position: relative; overflow: hidden;">

                                    <img src="../assets/10收费车道.webp" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        广东韶惠高速</p>
                                </div>


                                    <div @click="handleClick43" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                        <img src="../assets/甘肃金塔收费站.jpg" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                            甘肃金塔收费站</p>
                                    </div>

                                    <div @click="handleClick44" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                        <img src="../assets/广东汕昆高速.jpg" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                            广东汕昆高速</p>
                                    </div>
                                </div>




<!-- 
                                <div
                                    style="width: 100%;margin-top: 10px; display: flex; justify-content: center; align-items: center;">



                                    <div @click="handleClick45" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 400px; height: 350px;    position: relative; overflow: hidden;">

                                        <img src="../assets/501.png" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                            江西省寻龙高速公路新建工程机电工程项目</p>
                                    </div>


                                    <div @click="handleClick46" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 400px; height: 350px;  margin-left: 26px;  position: relative; overflow: hidden;">

                                        <img src="../assets/501.png" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                            瑞丽至孟连高速公路机电工程车道设备采购合同</p>
                                    </div>

                                    <div @click="handleClick47" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 400px; height: 350px;  margin-left: 26px;  position: relative; overflow: hidden;">

                                        <img src="../assets/501.png" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                            会巧高速公路机电项目光电标志物资设备采购合同</p>
                                    </div>
                                </div>










                         










                         
                                <div>









                                    <div
                                        style="width: 100%; display: flex; justify-content: center; align-items: center;margin-top: 30px;">
                                        <div @click="handleClick48" @mouseover="chaImg" @mouseout="chaoutImg"
                                            style="width: 400px; height: 350px;    position: relative;overflow: hidden;">
                                            <img src="../assets/502.png" alt="" style="float: left;">
                                            <p
                                                style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;width: 100%; height: 40px; position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;">
                                                广东省交通集团有限公司营运高速公路机电运维备品备件采购项目车道及隧道指示类设备采购</p>
                                        </div>
                                        <div @click="handleClick42" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 826px; height: 350px; margin-left: 26px;   position: relative; overflow: hidden;">

                                        <img src="../assets/501.png" alt=""
                                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                            连霍高速公路(G30)新疆境内乌鲁木齐至奎屯段改扩建工程第WKJD-5标段无缝LED拼接屏及费显采购</p>
                                    </div>





                                     
                                    </div>









                                    <div
                                        style="width: 100%;margin-top: 10px; display: flex; justify-content: center; align-items: center;">



                                        <div @click="handleClick50" @mouseover="chaImg" @mouseout="chaoutImg"
                                            style="width: 400px; height: 350px;    position: relative; overflow: hidden;">

                                            <img src="../assets/501.png" alt=""
                                                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                            <p
                                                style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                                德州至上饶高速公路合肥至枞阳段机电工程项目车道设备及隧道电光标志采购</p>
                                        </div>


                                        <div @click="handleClick40" @mouseover="chaImg" @mouseout="chaoutImg"
                                        style="width: 400px; height: 350px;margin-left: 26px;    position: relative;overflow: hidden;">
                                        <img src="../assets/502.png" alt="" style="float: left;">
                                        <p
                                            style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;width: 100%; height: 40px; position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;">
                                            银川至北海高速公路建始（陇里）到恩施（罗针田）段机电工程项目雾灯、费显等设备采购</p>
                                    </div>







                                       

                                        <div @click="handleClick52" @mouseover="chaImg" @mouseout="chaoutImg"
                                            style="width: 400px; height: 350px;  margin-left: 26px;  position: relative; overflow: hidden;">

                                            <img src="../assets/501.png" alt=""
                                                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                            <p
                                                style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                                G2201兰州南绕城高速八里镇出入口新建项目机电专业分包工程</p>
                                        </div>
                                    </div>

                                </div>




 -->

                                <!-- 大的 div 结束标签 -->
                        
                         



                        </t-tab-panel>

                        <!--  -->



                        <t-tab-panel value="four" style="height:auto;margin-bottom: .0938rem;" label="LED显示标志系列">


                            <div
                                style="width: 100%;margin-top: .0521rem;margin-top: .1563rem; display: flex; justify-content: center; align-items: center;">

<!-- 

                                <div @click="handleClick56" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;    position: relative; overflow: hidden;">

                                    <img src="../assets/501.png" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        省道S11曲靖至砚山公路曲靖（麒麟区）至师宗段高速公路费额显示器采购</p>
                                </div> -->

                                

                                <div @click="handleClick56" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;    position: relative; overflow: hidden;">

                                    <img src="../assets/白岩子隧道 改.webp" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        白岩子隧道</p>
                                </div>


                                <div @click="handleClick57" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 4.3021rem; height: 1.8229rem; margin-left: .1354rem;   position: relative; overflow: hidden;">

                                    <img src="../assets/bsy.png" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        彪水岩隧道</p>
                                </div>
<!-- 
                                <div @click="handleClick57" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 400px; height: 350px;  margin-left: 26px;  position: relative; overflow: hidden;">

                                    <img src="../assets/501.png" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                        广州从化至清远连州高速公路机电工程</p>
                                </div> -->
<!-- 
                                <div @click="handleClick58" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 400px; height: 350px;  margin-left: 26px;  position: relative; overflow: hidden;">

                                    <img src="../assets/501.png" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                        云南省大南高速公路隧道机电工程光电标志</p>
                                </div> -->
                                
                                <!-- <div @click="handleClick58" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 400px; height: 350px;  margin-left: 26px;  position: relative; overflow: hidden;">

                                    <img src="../assets/云南省红河哈尼族释族自区 改图.jpg" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                        云南省红河哈尼族释族自区设备采购</p>
                                </div> -->
                            </div>

                            <div
                                style="width: 100%;margin-top: .0521rem;margin-top: .1563rem; display: flex; justify-content: center; align-items: center;">

                                <div @click="handleClick60" @mouseover="chaImg" @mouseout="chaoutImg"
                                style="width: 2.0833rem; height: 1.8229rem;   position: relative; overflow: hidden;">

                                <img src="../assets/8lex显示.webp" alt=""
                                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                <p
                                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                    广东兴汕高速</p>
                            </div>

                             


                                <div @click="handleClick54" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                    <img src="../assets/2lex显示.webp" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        广东大潮高速</p>
                                </div>

                                <!-- <div @click="handleClick55" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                    <img src="../assets/501.png" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        蒙自至屏边高速公路交通工程收费、环境检测、紧急电话、对讲广播、可变标志灯系统设备采购项目</p>
                                </div> -->

                                <div @click="handleClick55" @mouseover="chaImg" @mouseout="chaoutImg"
                                style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                <img src="../assets/4012改.webp" alt=""
                                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                <p
                                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                    安徽宣城4012国道</p>
                            </div>



                            </div>



<!-- 
                            <div
                                style="width: 100%;margin-top: 10px;margin-top: 30px; display: flex; justify-content: center; align-items: center;">



                                <div @click="handleClick59" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 400px; height: 350px;    position: relative; overflow: hidden;">

                                    <img src="../assets/501.png" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                        攀枝花阳光大道北延线机电工程光电标志设备采购</p>
                                </div>


                               


                                <div @click="handleClick53" @mouseover="chaImg" @mouseout="chaoutImg"
                                style="width: 400px; height: 350px; margin-left: 26px;   position: relative; overflow: hidden;">

                                <img src="../assets/501.png" alt=""
                                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                <p
                                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                    云南省腾猴高速公路隧道机电工程项目</p>
                            </div>

                                <div @click="handleClick61" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 400px; height: 350px;  margin-left: 26px;  position: relative; overflow: hidden;">

                                    <img src="../assets/501.png" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px; font-weight: 400;padding-left: 10px;box-sizing: border-box;word-wrap: break-word;">
                                        四川乐西高速公路工程机电设备(线路设备)集中采购项目</p>
                                </div>
                            </div> -->




                        </t-tab-panel>

                        <t-tab-panel value="Five" style="height:auto;margin-bottom: .0938rem;" label="LED照明系列">




                            <div
                                style="width: 100%;margin-top: .0521rem;margin-top: .1563rem; display: flex; justify-content: center; align-items: center;">

                                <div @click="handleClick63" @mouseover="chaImg" @mouseout="chaoutImg"
                                style="width: 2.0833rem; height: 1.8229rem;   position: relative; overflow: hidden;">

                                <img src="../assets/2照明.webp" alt=""
                                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                <p
                                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                    安徽宿州中心</p>
                            </div>


                                <div @click="handleClick62" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem; margin-left: .1354rem;    position: relative; overflow: hidden;">

                                    <img src="../assets/代替.webp" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        广州顺光照明工程</p>
                                </div>


                              
                                <div @click="handleClick64" @mouseover="chaImg" @mouseout="chaoutImg"
                                    style="width: 2.0833rem; height: 1.8229rem;  margin-left: .1354rem;  position: relative; overflow: hidden;">

                                    <img src="../assets/代替.webp" alt=""
                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                                    <p
                                        style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%); opacity: 1;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;">
                                        萧县分中心机电工程</p>
                                </div>
                            </div>




                        </t-tab-panel>

                    </t-tabs>
                </t-space>

            </div>
        </div>
    </div>


</template>

<script>
    export default {

        data() {
            return {
                currentPage: 1,
                pages: [1, 2],
                currentPage1: 1,

                isShown: false,

                productCenterImage: require('../assets/pood1.webp'),
                activeName: 'first',

            };
        },
        //         mounted() {
        //   this.handleTabChange(this.$route.query.tab);
        // },
        // watch: {
        //   '$route.query.tab': {
        //     immediate: true,
        //     handler(newTab) {
        //       this.handleTabChange(newTab);
        //     }
        //   }
        // },
        // mounted() {
        //     const tab = this.$route.query.tab;
        //     if (tab === 'first') {
        //         this.value2 = 'first'; // 设置第一个选项卡为激活状态
        //     } else if (tab === 'second') {
        //         this.value2 = 'second'; // 设置第二个选项卡为激活状态
        //     } else if (tab === 'third') {
        //         this.value2 = 'third'; // 设置第三个选项卡为激活状态
        //     } else if (tab === 'four') {
        //         this.value2 = 'four';
        //     } else if (tab === 'Five') {
        //         this.value2 = 'Five';
        //     } else if (tab === 'six') {
        //         this.value2 = 'six';
        //     }
        //     else if (tab === 'seven') {
        //         this.value2 = 'seven';
        //     }else if (tab === 'eight') {
        //         this.value2 = 'eight';
        //     }else if (tab === 'nine') {
        //         this.value2 = 'nine';
        //     }




        // },
        mounted() {
            this.handleTabChange(this.$route.query.tab);
        },
        watch: {
            '$route.query.tab': {
                immediate: true,
                handler(newTab) {
                    this.handleTabChange(newTab);
                }
            }
        },


        methods: {

            changeTa(event) {

                const cor = event.currentTarget.querySelector('i');
                cor.style.color = "#407BF5";
                const img = event.currentTarget.querySelector('img');
                img.style.transition = "0.6s"; // 设置动画过渡时间为0.5秒
                img.style.transform = "scale(1.2)"; // 设置图片放大1.2倍
                const opo = event.currentTarget.querySelector('.firP');
                opo.style.fontSize = .1302 + "rem"; 
                opo.style.transition = "0.6s";
                const opoT = event.currentTarget.querySelector('.seodP');
                opoT.style.fontWeight = "bold";
                opoT.style.transition = "0.6s";
            },

            changeOut(event) {
                const cor = event.currentTarget.querySelector('i');
                cor.style.color = "gray"
                const img = event.currentTarget.querySelector('img');
                img.style.transition = "0.5s"; // 设置动画过渡时间为0.5秒
                img.style.transform = "scale(1)"; // 设置图片放大1.2倍
                const opo = event.currentTarget.querySelector('.firP');
                opo.style.fontSize = .0938 + "rem"; 
                opo.style.transition = "0.5s";
                const opoT = event.currentTarget.querySelector('.seodP');
                opoT.style.fontWeight = null;
                opoT.style.transition = "0.5s";
            },

            chaImg(event) {
                const img = event.currentTarget.querySelector('img');
                img.style.transition = "0.5s"; // 设置动画过渡时间为0.5秒
                img.style.transform = "scale(1.2)"; // 设置图片放大1.2倍
                img.style.overflow = "hidden"; // 超出部分隐藏
                const pl = event.currentTarget.querySelector('p');
                pl.style.transition = "0.2s";
                pl.style.fontSize = .0938 + "rem"
                pl.style.background = "linear-gradient(180deg, rgba(64, 123, 245, 0.8) 0%, rgba(42, 98, 200, 0.8) 100%)";
            },

            chaoutImg(event) {
                const img = event.currentTarget.querySelector('img');
                img.style.transition = "0.5s"; // 清除过渡效果
                img.style.transform = ""; // 清除变换效果
                img.style.overflow = ""; // 清除overflow属性
                const pl = event.currentTarget.querySelector('p');
                pl.style.opacity = "1";
                pl.style.fontSize = .0833 + "rem" ;
                pl.style.transition = "0.4s";
                pl.style.background = "linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%)"
            },

            changePage(page) {
                this.currentPage = page;

            },

            changePage1(page1) {
                this.currentPage1 = page1;
            },

            prevPage() {
                if (this.currentPage > 1) {
                    this.currentPage--;
                }
            },

            prevPage1() {
                if (this.currentPage1 > 1) {
                    this.currentPage1--;
                }
            },

            nextPage1() {
                if (this.currentPage1 < this.pages.length) {
                    this.currentPage1++;
                }
            },

            nextPage() {
                if (this.currentPage < this.pages.length) {
                    this.currentPage++;
                }
            },
            handleTabChange(tab) {
                if (tab === 'first') {
                    this.activeName = 'first'; // 设置第一个选项卡为激活状态
                } else if (tab === 'second') {
                    this.activeName = 'second'; // 设置第二个选项卡为激活状态
                } else if (tab === 'third') {
                    this.activeName = 'third'; // 设置第三个选项卡为激活状态
                }
                else if (tab === 'four') {
                    this.activeName = 'four'; // 设置第三个选项卡为激活状态
                }
                else if (tab === 'Five') {
                    this.activeName = 'Five'; // 设置第三个选项卡为激活状态
                }
                else if (tab === 'six') {
                    this.activeName = 'six'; // 设置第三个选项卡为激活状态
                } else if (tab === 'seven') {
                    this.activeName = 'seven'; // 设置第三个选项卡为激活状态
                }
                else if (tab === 'eight') {
                    this.activeName = 'eight'; // 设置第三个选项卡为激活状态
                }
                else if (tab === 'nine') {
                    this.activeName = 'nine'; // 设置第三个选项卡为激活状态
                }
                console.log(tab); // 打印传递过来的 tab 参数

            },
            zoomInImage(event) {
                const img = event.currentTarget.querySelector('img');
                img.style.transition = "0.3s";
                img.style.transform = 'scale(1.2)';
                const divp = event.currentTarget.querySelector('.divp');
                divp.style.display = 'block';
                const op = event.currentTarget.querySelector('p');
                op.style.color = "#407BF5";
                op.style.transition = "0.3s";
                op.style.left = .0521 + "rem";  
                // op.style.width = 120 + 'px';
                op.style.fontWeight = "bold";
                op.style.fontSize = + "rem"; 
               
            },

            //另外当前元素的divp
            zoomOutImage(event) {
                const img = event.currentTarget.querySelector('img');
                img.style.transform = 'scale(1)';
                const divp = event.currentTarget.querySelector('.divp');
                divp.style.display = 'none';
                const op = event.currentTarget.querySelector('p');
                op.style.left = null;
                op.style.width = null;
              
                op.style.fontWeight = null;
                op.style.fontSize = .0938  + "rem";
                op.style.color = "#407BF5";
            },
    
            handleClick1() {
                this.$router.push('/casePagination1');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick2() {
                this.$router.push('/casePagination2');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick3() {
                this.$router.push('/casePagination3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick4() {
                this.$router.push('/casePagination4');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick5() {
                this.$router.push('/casePagination5');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick6() {
                this.$router.push('/casePagination6');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick7() {
                this.$router.push('/casePagination7');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick88() {
                  this.$router.push('/casePagination66');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick9() {
                this.$router.push('/casePagination68');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick10() {
                this.$router.push('/casePagination10');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
               handleClick11() {
                this.$router.push('/casePagination11');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick12() {
                this.$router.push('/casePagination12');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick13() {
                this.$router.push('/casePagination13');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick14() {
                this.$router.push('/casePagination14');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
               handleClick15() {
                this.$router.push('/casePagination15');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
               handleClick16() {
                this.$router.push('/casePagination16');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
               handleClick17() {
                this.$router.push('/casePagination17');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick18() {
                this.$router.push('/casePagination18');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick19() {
                this.$router.push('/casePagination19');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
               handleClick20() {
                this.$router.push('/casePagination20');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick21() {
                this.$router.push('/casePagination21');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick22() {
                this.$router.push('/casePagination22');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
               handleClick23() {
                this.$router.push('/casePagination23');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick24() {
                this.$router.push('/casePagination24');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick25() {
                this.$router.push('/casePagination25');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick26() {
                this.$router.push('/casePagination26');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick27() {
                this.$router.push('/casePagination27');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick28() {
                this.$router.push('/casePagination28');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

               handleClick29() {
                this.$router.push('/casePagination29');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick30() {
                this.$router.push('/casePagination30');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick31() {
                this.$router.push('/casePagination31');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick32() {
                this.$router.push('/casePagination32');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick33() {
                this.$router.push('/casePagination33');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick34() {
                this.$router.push('/casePagination34');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick35() {
                this.$router.push('/casePagination35');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

               handleClick36() {
                this.$router.push('/casePagination36');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
               handleClick37() {
                this.$router.push('/casePagination37');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick38() {
                this.$router.push('/casePagination38');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick39() {
                this.$router.push('/casePagination39');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick40() {
                this.$router.push('/casePagination40');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick41() {
                this.$router.push('/casePagination41');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            handleClick42() {
                this.$router.push('/casePagination42');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick43() {
                this.$router.push('/casePagination43');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick44() {
                this.$router.push('/casePagination44');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick45() {
                this.$router.push('/casePagination45');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick46() {
                this.$router.push('/casePagination46');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick47() {
                this.$router.push('/casePagination47');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick48() {
                this.$router.push('/casePagination48');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick49() {
                this.$router.push('/casePagination49');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick50() {
                this.$router.push('/casePagination50');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick51() {
                this.$router.push('/casePagination51');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick52() {
                this.$router.push('/casePagination52');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick53() {
                this.$router.push('/casePagination53');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick54() {
                this.$router.push('/casePagination54');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick55() {
                this.$router.push('/casePagination55');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick56() {
                this.$router.push('/casePagination56');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick57() {
                this.$router.push('/casePagination57');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick58() {
                this.$router.push('/casePagination58');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick59() {
                this.$router.push('/casePagination59');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick60() {
                this.$router.push('/casePagination60');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick61() {
                this.$router.push('/casePagination61');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick62() {
                this.$router.push('/casePagination62');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick63() {
                this.$router.push('/casePagination63');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            handleClick64() {
                this.$router.push('/casePagination64');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar3() {
                this.$router.push('/millimeterWaveRadar3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar4() {
                this.$router.push('/millimeterWaveRadar4');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar5() {
                this.$router.push('/millimeterWaveRadar5');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar6() {
                this.$router.push('/millimeterWaveRadar6');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar7() {
                this.$router.push('/millimeterWaveRadar7');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar8() {
                this.$router.push('/millimeterWaveRadar8');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar9() {
                this.$router.push('/millimeterWaveRadar9');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome1() {
                this.$router.push('/mationBan1');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome2() {
                this.$router.push('/mationBan2');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome3() {
                this.$router.push('/mationBan3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome4() {
                this.$router.push('/mationBan4');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToHome5() {
                this.$router.push('/mationBan5');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome6() {
                this.$router.push('/mationBan6');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome7() {
                this.$router.push('/mationBan7');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome8() {
                this.$router.push('/mationBan8');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome9() {
                this.$router.push('/mationBan9');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome10() {
                this.$router.push('/mationBan10');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome11() {
                this.$router.push('/mationBan11');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome12() {
                this.$router.push('/mationBan12');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome13() {
                this.$router.push('/mationBan13');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome14() {
                this.$router.push('/mationBan14');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome15() {
                this.$router.push('/mationBan15');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome16() {
                this.$router.push('/mationBan16');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToDis1() {
                this.$router.push('/ledDisplay1');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToDis2() {
                this.$router.push('/ledDisplay2');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis3() {
                this.$router.push('/ledDisplay3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis4() {
                this.$router.push('/ledDisplay4');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis5() {
                this.$router.push('/ledDisplay5');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis6() {
                this.$router.push('/ledDisplay6');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis7() {
                this.$router.push('/ledDisplay7');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis8() {
                this.$router.push('/ledDisplay8');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis9() {
                this.$router.push('/ledDisplay9');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis10() {
                this.$router.push('/ledDisplay10');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis11() {
                this.$router.push('/ledDisplay11');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis12() {
                this.$router.push('/ledDisplay12');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToLight1() {
                this.$router.push('/ledLighting1');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToLight2() {
                this.$router.push('/ledLighting2');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToLight3() {
                this.$router.push('/ledLighting3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToLight4() {
                this.$router.push('/ledLighting4');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToLight5() {
                this.$router.push('/ledLighting5');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic1() {
                this.$router.push('/inTrans1');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic2() {
                this.$router.push('/inTrans2');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic3() {
                this.$router.push('/inTrans3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic4() {
                this.$router.push('/inTrans4');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic5() {
                this.$router.push('/inTrans5');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic6() {
                this.$router.push('/inTrans6');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic7() {
                this.$router.push('/inTrans7');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic8() {
                this.$router.push('/inTrans8');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic9() {
                this.$router.push('/inTrans9');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            changeTab(newValue) {
                this.value2 = newValue;
                this.$emit('tabChanged', newValue);
                if (newValue === 'first') {
                    this.productCenterImage = require('../assets/pood1.webp');
                } else if (newValue === 'second') {
                    this.productCenterImage = require('../assets/pod1.png');
                } else if (newValue === 'third') {
                    this.productCenterImage = require('../assets/pood2.png');
                } else if (newValue === 'four') {
                    this.productCenterImage = require('../assets/pood3.png');
                } else if (newValue === 'Five') {
                    this.productCenterImage = require('../assets/pood5.png');
                } else if (newValue === 'six') {
                    this.productCenterImage = require('../assets/pood5.webp');
                } else if (newValue === 'seven') {
                    this.productCenterImage = require('../assets/pood5.png');
                }
                // 添加您已有的change事件的逻辑代码
            },
        }
    }

</script>

<style scoped>

.contain /deep/ .t-tabs {
        border: none;
    box-shadow: none;
    }

   



    /* .contain /deep/ .t-tabs__nav-scroll {} */



    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item {
        padding-right: .1563rem;
        padding-left: .1563rem;
    
     
      
      
    }

    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item:not(:first-of-type) {
       
        border-left: none;
        border-right: none;
       
    }

    .contain /deep/ .t-tabs__nav-scroll{
        display: flex;justify-content: center;align-items: center;
    }
    .contain /deep/  .t-tabs__nav--card.t-tabs__nav-item.t-is-active {

        border-right-color: #2056c0;
      
       
    }




    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item.t-is-active {
        border-bottom-color: #2056c0;
       
    }

    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item:not(:first-of-type) {
        border-left: none;
    }

    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item.t-is-active {
        border-right-color: #2056c0;
    }

    .contain /deep/ .t-tabs {
        /* border: none;
    box-shadow: none; */
    }

    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item.t-is-active {
        border-right-color: #2056c0;

    }

    .page2 {
        background-color: #5B5B5B;
    }


    h1 {
        color: blue;
    }

    /* .opp /deep/ .t-tabs__nav--card.t-tabs__nav-item.t-is-active{
        border-right-color: #407BF5;
    }
    .opp /deep/ .t-space-vertical {
        border: none;
        box-shadow: none;
    }

    .opp /deep/ .el-tabs__nav-scroll {
        background-color: #E9E9E9;

    } */



    .div1 {
        background-image: url(../assets/PG6.png)
    }

    .div2 {
        background-image: url(../assets/PG7.png)
    }

    .div3 {
        background-image: url(../assets/PG8.png)
    }

    .div4 {
        background-image: url(../assets/PG9.png)
    }



    .pagination {
        display: flex;
        list-style: none;
    }

    .pagination li {
        margin: 0 .026rem;
        cursor: pointer;
        text-align: center;
        line-height: .2083rem;
        font-size: .1042rem;
        width: .2083rem;
        height: .2083rem;
        background-color: #ffffff;
        color: #4e4949;
    }

    .pagination li.active {
        background-color: #3a78f5f9;
    }

    /* .page-content {
        display: none;
    } */

    .page-content.active {
        display: block;
    }


    /* @media screen and (max-width: 1439px) {
        .opp{
            transform: scale(0.80);
        }

  
    
   
  
}

@media screen and (min-width: 1426px) and (max-width: 1510px) {

   


} */
  
</style>