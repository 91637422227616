<template>
  <div id="app">
    <router-view> </router-view>
  </div>
</template>

<script>
  
export default {
  mounted() {
    this.setRem();
    window.addEventListener('resize', this.setRem);
    document.querySelector('link[rel="icon"]').setAttribute('href', './');
  },
  methods: {
    setRem() {
      const html = document.documentElement;
      const width = html.getBoundingClientRect().width;
      html.style.fontSize = width / 10 + 'px';
    }
  }
}
</script>

<style scoped>
#app {
  margin: 0;
  font-family: PingFang SC, PingFang SC;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 18px;
  font-weight: 400;
   
}

</style>