<template>

    <div>
    <div style="font-family: Arial, '微软雅黑';font-size:.0729rem; color: #999;padding-top: .1042rem;
    line-height: .125rem;">
      <P style="font-size: .125rem;width: 100%;flex-wrap: wrap;margin-top: .3125rem;
      color: #333;
      text-align: center;">新三板分层落地，丰海科技首批进入创新层
      </P>
  
      <p style="width: 100%;
      text-align: center;font-size: .0625rem;
      margin-top: .0781rem;"> 2016-06-29
      </p>
  
      <div style="width:6.5104rem;margin: 0 auto;padding-bottom: .0781rem;border-bottom: #eee solid .0052rem;">
      </div>
  
  
  
      <div style="width:6.5104rem;color: #666666;font-size: .0833rem;font-family: 'HarmonyOS_Sans_SC_Regular';margin: 0 auto;padding-top: .1302rem;padding-bottom: .6771rem;border-bottom: #eee solid .0052rem;position: relative;">
        <p style="text-indent: .1563rem;">
          
            根据全国中小企业股份转让系统2016年6月24日发布的《关于正式发布创新层挂牌公司名单的公告》（股转系统公告【2016】50号），共有953家新三板公司入选创新层，广州市丰海科技股份有限公司（公司简称：丰海科技，证券代码：830862）从7500多家挂牌企业中脱颖而出，首批进入了创新层。分层制度被看作是2016年新三板最受关注的政策红利，分层制度的推出标志着新三板市场进入了一个全新的发展阶段。
           
        </p>
    
  
        <p style="text-indent: .1563rem;">
            对于丰海科技，能够首批进入创新层，不仅是对企业现有经营成果的肯定，更是为企业下一步发展打下了良好基础。借助新三板分层的契机，丰海科技将获得更大的发展空间。
         
        </p>
  
        <div style="position: absolute; bottom: 0;right: 0;">
          <div class="social-share" data-initialized="true">
            <span style="margin-right: .0521rem;">分享到:</span>
            <a href="http://www.weibo.com" class="social-share-icon icon-weibo"></a>
            <a href="#" class="social-share-icon icon-qq"></a>
            <a href="#" class="social-share-icon icon-qzone"></a>
            <a href="#" class="social-share-icon icon-wechat"></a>
            
        </div>
  
        </div>
  
      </div>
      
  
      <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem;">
        <el-button @click="goBack">返回列表</el-button>
      </div>
  
    </div>
     
    </div>
  </template>
  
  <script>
  
  
    export default {
      methods: {
      goBack() {
        history.back();
      }
    }
  }
  
  
  
  
  </script>