<template>

  <div>
  <div style="font-family: Arial, '微软雅黑';font-size:.0729rem; color: #999;padding-top: .1042rem;
  line-height: .125rem;">
    <P style="font-size: .125rem;width: 100%;flex-wrap: wrap;margin-top: .3125rem;
    color: #333;
    text-align: center;">丰海科技与你相约PWTC展会
    </P>

    <p style="width: 100%;
    text-align: center;font-size: .0625rem;
    margin-top: .0781rem;"> 2017-08-06</p>

    <div style="width:6.5104rem;margin: 0 auto; padding-bottom: .0781rem;border-bottom: #eee solid .0052rem;">
    </div>



    <div style="width: 6.5104rem;margin: 0 auto; padding-top: .1302rem;padding-bottom: .6771rem;border-bottom: #eee solid .0052rem;position: relative; color: #666666;font-size: .0833rem;font-family: 'HarmonyOS_Sans_SC_Regular';">
      <p style="text-indent: .1563rem;">
        2017年7月底，我司国际业务部与研发中心参加吉隆坡PWTC展会，使我司产品真正实现走出去。
      </p>
      <p style="text-indent: .1563rem;">
       公司此次参展以拓宽视野、开启思路、学习先进、交流合作为主，充分利用本次参展机会与前来参观的客户和经销商进行交流、沟通、洽谈，进一步提升了公司品牌的知名度和影响力，同时也进一步了解同行先进企业的产品特点，以便更好地完善自身产品结构，发挥自身优势。

      </p>

      <p style="text-indent: .1563rem;">
        通过此次展会，我公司收获颇丰，我们将继续努力，让更多的人认识了解我们的产品。
      </p>

      <p style="text-indent: .1563rem;">
       
      </p>

      <div style="position: absolute; bottom: 0;right: 0;">
        <div class="social-share" data-initialized="true">
          <span style="margin-right: .0521rem;">分享到:</span>
          <a href="http://www.weibo.com" class="social-share-icon icon-weibo"></a>
          <a href="#" class="social-share-icon icon-qq"></a>
          <a href="#" class="social-share-icon icon-qzone"></a>
          <a href="#" class="social-share-icon icon-wechat"></a>
          
      </div>

      </div>

    </div>
    

    <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem;">
      <el-button @click="goBack">返回列表</el-button>
    </div>

  </div>
   
  </div>
</template>

<script>


  export default {
    methods: {
    goBack() {
      history.back();
    }
  }
}




</script>