<template>

  <div class="contain">

    <div
      :style="{ width: '100%', height: '3.3333rem', backgroundImage: 'url(' + require('../assets/服务支持1234.webp') + ')' , backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }">
    </div>
    <!-- 轮播图部分 -->

    <div
      style="width: 100%; height: .6823rem; display: flex; justify-content: center; align-items: center; position: relative;">
      <img style="margin: 0 auto;position: absolute;height: .1875rem;" src="../assets/联系我们.png" alt="">

      <p
        style="font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0938rem;">
        联系我们
      </p>

      <p
        style=" display: inline-block; height: .0104rem; width: .3333rem;background-color:  #407BF5;;font-size: .1667rem ; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0417rem;">
      </p>

    </div>


    <div style="width:6.5104rem;margin: 0 auto;" class="chan">

      <ul class="ulJoin" style="list-style: none; display: flex ; justify-content: flex-start ; ">
        <li @click="changeColor(0)" :style="{backgroundColor: activeIndex === 0 ? '#407BF5' : '' , color : activeIndex === 0 ? '#fff' : ''}"> 加入我们 </li>
        <li @click="changeColor(1)" :style="{backgroundColor: activeIndex === 1 ? '#407BF5' : '' , color : activeIndex === 1 ?'#fff' : ''}"> 联系我们 </li>
      </ul>
    </div>
    <!--  -->
    <router-view> </router-view>

    <!-- <div class="opp" style="width:1399px;font-size: 18px;margin-top: 20px;">
      <el-tabs v-model="activeName" type="border-card" style="position: relative;padding-bottom: 10px;">
          <el-tab-pane label="公司新闻" name="first">
          </el-tab-pane>
          <el-tab-pane name="second" label="行业动态">行业动态</el-tab-pane>
      </el-tabs>
  </div> -->

  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeIndex: 0,
        activeName: 'first',
        tableData: [{
          date: '2024-01-15',
          name: '软件工程师',
        },
         {
          date: '2024-01-15',
          name: '硬件工程师'
        },
        {
          date: '2024-01-15',

          name: '销售工程师'
        }],
        tableData1: [{
          date: '2024-01-15',
          name: '应届毕业生',
        }, {
          date: '2024-01-15',

          name: '售后工程师'
        },
        {
          date: '2024-01-15',

          name: '嵌入式软件工程师'
        },
        {
          date: '2024-01-15',
          name: '硬件工程师'
        },

        {
          date: '2024-01-15',
          name: '高级软件工程师'
        }],
        pageTitle: "加入我们",
        positions: [
          {
            id: 1,
            title: "前端工程师",
            description: "负责开发和维护公司网站前端部分"
          },
          {
            id: 2,
            title: "后端工程师",
            description: "负责开发和维护公司网站后端部分"
          },
          {
            id: 3,
            title: "UI/UX设计师",
            description: "负责设计用户界面和用户体验"
          }
        ]
      };
    },

    mounted() {
      this.updateActiveIndexBasedOnRoute(); // 当组件挂载时，根据路由更新activeIndex

    },
    watch: {
      '$route.path': function () {
        this.updateActiveIndexBasedOnRoute(); // 当路由变化时，更新activeIndex
      }
    },
    methods: {
      updateActiveIndexBasedOnRoute() {
        // 检查当前的路由并更新activeIndex
        const path = this.$route.path;
        if (path.includes('joinGo')) {
          this.activeIndex = 1;
        } else if (path.includes('justGo')) {
          this.activeIndex = 0;
        }
      },


      changeColor(index) {
        this.activeIndex = index;
        if (index === 0) {
          this.$router.push({ path: 'justGo' }); // 跳转到名为'join'的路由
        }
        if (index === 1) {
          this.$router.push({ path: 'joinGo' }); // 跳转到名为'join'的路由
        }

      }






    }
  };
</script>
<style scoped>
  .about-us {
    max-width: 6.25rem;
    margin: 0 auto;
    padding: .2083rem;
  }

  .banner {
    text-align: center;
    margin-bottom: .2083rem;
  }

  .banner-title {
    font-size: .1875rem;
    font-weight: bold;
    color: #333;
  }

  .banner-image {
    width: 100%;
    max-height: 2.0833rem;
    object-fit: cover;
    border-radius: .0417rem;
  }

  .content {
    margin-bottom: .2083rem;
  }

  .section {
    margin-bottom: .1042rem;
  }

  .section-title {
    font-size: .125rem;
    font-weight: bold;
    color: #333;
  }

  .section-text {
    font-size: .0833rem;
    color: #555;
  }

  .team-title {
    font-size: .125rem;
    font-weight: bold;
    color: #333;
    margin-bottom: .1042rem;
  }

  .team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .team-member {
    display: flex;
    align-items: center;
    margin: .0521rem;
  }

  .item-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
  }

  .opp /deep/ .el-tabs__nav-scroll {
    background-color: #E9E9E9;

  }

  .frp {
    background-color: #E9E9E9;
    width: .1042rem;
    height: .1042rem;
  }

  .title-container {
    flex-basis: 100%;
  }

  .item-title {
    /* 标题样式 */
  }

  .opp /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    border-right-color: #407BF5;
  }

  .opp /deep/ .el-tabs--border-card {
    border: none;
    box-shadow: none;
  }

  .item-image {
    margin-left: auto;
  }

  .item-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;

  }
  .ulJoin li{
    font-size: .0833rem;
  }

  .member-avatar {
    width: .4167rem;
    height: .4167rem;
    object-fit: cover;
    border-radius: 50%;
  }

  .member-details {
    margin-left: .0521rem;
  }

  .member-name {
    font-size: .0833rem;
    font-weight: bold;
    color: #333;
  }

  .member-role {
    font-size: .0729rem;
    color: #555;
  }

  .contain /deep/ .el-breadcrumb {
    font-size: .0938rem;
  }

  .year {


    height: .2083rem;
    line-height: .2083rem;
    font-size: .0833rem;
    border-bottom: #eee solid .0052rem;
    width: .8854rem;
    padding-left: .125rem;
  }

  .year b {
    font-size: .1563rem;
    color: #bb3944;
    font-weight: bold;
  }

  .el-tabs__item {
    width: 1.5625rem;
    /* 设置每个el-table-pane的宽度 */
  }

  .el-tabs.el-tabs--top {
    width: 6.25rem;
  }

  #tab-first .el-tabs__item.is-top {
    width: 1.5625rem;
  }



  .chan li {
    width: .7813rem;
    height: .2083rem;
    text-align: center;
    line-height: .2083rem;
    font-weight: bold;
  }
</style>