<template>
    <div>
      <div style="width: 6.5104rem;margin: 0 auto;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/product-center', query: { tab: 'eight' }}">智能雾灯、劝导装置系列</el-breadcrumb-item>
          <el-breadcrumb-item>交通劝导装置</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
  
      <div style="display: flex;justify-content: center;margin-top: .4167rem;">
        <div>
          <img src="../../assets/018.webp" alt="" style="height: 1.1198rem;">
        </div>
  
        <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
          <p class="name">交通劝导装置</p>
          <P class="content" style="text-indent: .1198rem;font-size: .0833rem">智能交通劝导装置，又分为弯道会车预警系统、停车场出口安全预警系统和来车预警系统。除了应用于高速公路、城市道路等各类道路的车速反馈提醒外，还可应用于停车场出口急陡坡、山区道路、交叉路口等存在视觉盲区的来车预警等。
          </P>
          <div style="margin-top: .2604rem;">
            <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
              <icon class="el-icon-phone-outline"></icon>技术热线
            </div>
            <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
  
          </div>
  
  
        </div>
  
  
  
      </div>
  
      <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
        详细参数</div>
  
      <!--  style="width: 6.5104rem;margin: 0 auto;"-->
      <div class="msgList" style="width: 6.5104rem;margin: 0 auto;">
        <ul>
          <li>
            <div class="txt"><em>箱体外廓尺寸</em></div>
            <div class="msg">320mm*150mm*2300mm(高)</div>
          </li>
          <!-- ≥   ≤ -->
          <li>
            <div class="txt"><em>声音警示</em></div>
            <div class="msg">100 分贝</div>
          </li>
          <li>
            <div class="txt"><em>LED 显示屏</em></div>
            <div class="msg">P10 单红户外模组、像素 96*32
              </div>
          </li>
          <li>
            <div class="txt"><em>亮度</em></div>
            <div class="msg">≥4000cd/㎡</div>
          </li>
          <li>
            <div class="txt"><em>可视距离</em></div>
            <div class="msg">≥200m</div>
          </li>
          <li>
            <div class="txt"><em>环境温度</em></div>
            <div class="msg">-40°C~+70°C</div>
          </li>
          <li>
            <div class="txt"><em>可视角度</em></div>
            <div class="msg">≥30°</div>
          </li>
          <li>
            <div class="txt"><em>红蓝爆闪灯</em></div>
            <div class="msg">高亮 LED 灯，爆闪提示</div>
          </li>
          <li>
            <div class="txt"><em>供电电压</em></div>
            <div class="msg">AC220V+-44V 50Hz+-2Hz</div>
          </li>
          <li>
            <div class="txt"><em>外壳防护等级为</em></div>
            <div class="msg">IP56</div>
          </li>
       
        </ul>
      </div>
  
  
      <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
        <el-button @click="goBack">返回列表</el-button>
      </div>
  
  
    </div>
  
  
  
  
  </template>
  <script>
  
  
    export default {
      methods: {
        goBack() {
  this.$router.push({ path: '/product-center', query: { tab: 'eight' }});
  const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

}
    
        ,
  
        //       goTo(){
        //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
        //     const windowHeight = window.innerHeight; // 
        //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
        //     window.scrollTo(0, scrollTo); //
        //   },
      }
    }
  
  
  
  
  </script>
  
  <style scoped>
    .name {
      padding-bottom: .1042rem;
      border-bottom: #eeeeee solid .0052rem;
      font-size: .1042rem;
      color: #2056c0;
      font-weight: bold;
      position: relative;
    }
  
    .msgList {
      padding-top: .2604rem;
  
      border-bottom: #eee solid .0052rem;
    }
  
    .msgList li {
      border: #c6c6c6 solid .0052rem;
      padding: .0521rem .1042rem .0521rem 1.0938rem;
      position: relative;
      font-size: .0729rem;
      margin-bottom: .0521rem;
      text-align: center;
      line-height: .2604rem;
    }
  
    li {
      list-style-position: outside;
      list-style-type: none;
  
    }
  
    .msgList li .msg {
      line-height: .1667rem;
      color: #999;
    }
  
    .content {
      padding-top: .1563rem;
      font-size: .0729rem;
      line-height: .125rem;
      color: #434343;
  
    }
  
    .tel {
      padding-top: .0521rem;
      font-size: .1875rem;
      color: #bb3944;
      font-family: Impact;
    }
  
    .txt {
      position: absolute;
      left: 0rem;
      top: 0rem;
      width: .9896rem;
      height: 100%;
      text-align: center;
      color: #FFFFFF;
      background: #2056c0;
      font-size: .0729rem;
      font-family: Arial, "微软雅黑";
  
    }
  
    .tittle {
  
      margin-top: .5208rem;
      height: .2083rem;
      text-align: center;
      line-height: .2083rem;
      font-weight: bold;
      font-size: .0833rem;
      color: #fff;
  
      position: relative;
    }
  </style>