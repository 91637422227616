<template>
    <div>
        <div style="width: 1250px;margin:0 auto;transform: translateY(-30px) translateX(-20px);">

            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/casePresentation', query: { tab: 'six' }}">案例展示</el-breadcrumb-item>
                <el-breadcrumb-item :to="({ path: '/casePresentation', query: { tab: 'six' } })">雷达通信模组</el-breadcrumb-item>
            </el-breadcrumb>
        </div>


        <div style="width: 100%; margin-top: 10px;  display: flex; justify-content: center; align-items: center;">
            
            <div  @mouseover="chaImg" @mouseout="chaoutImg"
                style="width: 310px; height: 283px;position: relative;overflow: hidden;">
                <img src="../../assets/物联自组网雷达感应模块采购项目.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
                   width: 100%; height: 40px; position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px;
                   font-weight: 400;padding-left: 10px;box-sizing: border-box;">雷达通信模组</p>
            </div>

            <!-- <div style="width: 310px; height: 283px; background-color: #fff; margin-left: 26px;position: relative;">


        </div>
        <div style="width: 310px; height: 283px; background-color: #fff; margin-left: 26px;position: relative;">

        </div>
        <div style="width: 310px; height: 283px; background-color: #fff; margin-left: 26px;position: relative;">

        </div> -->
        </div>

        <div style="width:1250px;margin: 0 auto;padding-bottom: 15px;border-bottom: #eee solid 1px;">
        </div>

        <div style="display: flex;justify-content: center;margin-top: 25px;margin-bottom: 25px; ">
            <el-button @click="goBack">返回列表</el-button>
        </div>

    </div>




</template>


<script>

    export default {
        data() {
            return {

            }
        },

        methods: {
            goBack() {
                this.$router.push({ path: '/casePresentation', query: { tab: 'six' } });
            },
            chaImg(event) {
                const img = event.currentTarget.querySelector('img');
                img.style.transition = "0.5s"; // 设置动画过渡时间为0.5秒
                img.style.transform = "scale(1.2)"; // 设置图片放大1.2倍
                img.style.overflow = "hidden"; // 超出部分隐藏
                const pl = event.currentTarget.querySelector('p');
                pl.style.transition = "0.2s";
                pl.style.fontSize = 18 + "px"
                pl.style.background = "linear-gradient(180deg, rgba(64, 123, 245, 0.8) 0%, rgba(42, 98, 200, 0.8) 100%)";
            },


            chaoutImg(event) {
                const img = event.currentTarget.querySelector('img');
                img.style.transition = "0.5s"; // 清除过渡效果
                img.style.transform = ""; // 清除变换效果
                img.style.overflow = ""; // 清除overflow属性

                const pl = event.currentTarget.querySelector('p');
                pl.style.opacity = "1";
                pl.style.fontSize = 16 + "px"
                pl.style.transition = "0.4s";
                pl.style.background = "linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%)"
            },



        },

    }







</script>

<style scoped>

</style>