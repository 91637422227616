import { render, staticRenderFns } from "./millimeterWaveRadar3.vue?vue&type=template&id=40a06927&scoped=true"
import script from "./millimeterWaveRadar3.vue?vue&type=script&lang=js"
export * from "./millimeterWaveRadar3.vue?vue&type=script&lang=js"
import style0 from "./millimeterWaveRadar3.vue?vue&type=style&index=0&id=40a06927&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40a06927",
  null
  
)

export default component.exports