<template>
    <div>
        <div style="width: 6.5104rem;margin: 0 auto;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/product-center', query: { tab: 'four' }}">LED显示标志系列</el-breadcrumb-item>
                <el-breadcrumb-item>LED诱导标志控制器</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="display: flex;justify-content: center;margin-top: .4167rem;">
            <div>
                <img src="../../assets/051.webp" alt="" style="height: 1.1198rem;">

            </div>
            <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
                <p class="name">节能型隧道诱导指示标志</p>
                <P class="content" style="text-indent: .1198rem;font-size: .0833rem">节能型隧道诱导指示标志是一种专门设计用于隧道内部的节能型交通指示系统，旨在提供高效能见度和节能运行。节能型隧道诱导指示标志通过显示各种图案、箭头和文字等元素，向驾驶员提供准确的车道信息和导航指引，帮助他们安全通行。这些标志通常采用LED技术，具有低功耗、高亮度和长寿命的特点，能够在隧道内提供清晰可见的指示信息；此外，这些标志还配备光感应器和智能控制系统，能够根据环境光照自动调节亮度，以达到节能的目的。
                </P>
                <div style="margin-top: .1563rem;">
                    <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
                        <icon class="el-icon-phone-outline"></icon>技术热线
                    </div>
                    <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>

                </div>


            </div>



        </div>

        <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
            详细参数
        </div>

        <div class="msgList" style="width: 6.5104rem;margin: 0 auto;">
            <ul>
                <li>
                    <div class="txt"><em>机箱</em></div>
                    <div class="msg">铝框钢架边框材料,防水防尘防锈蚀,密封性IP65</div>
                </li>
                <li>
                    <div class="txt"><em>工作环境</em></div>
                    <div class="msg">温度-40°~65°,湿度10%~95%</div>
                </li>
                <li>
                    <div class="txt"><em>灯源材料</em></div>
                    <div class="msg">白色超高亮发光二极管</div>
                </li>
                <li>
                    <div class="txt"><em>备用电源</em></div>
                    <div class="msg">≥90分钟</div>
                </li>
                <li>
                    <div class="txt"><em>产品亮点</em></div>
                    <div class="msg">1.内嵌导光板,使图案显示色彩均匀,功耗更低。2.方便快捷的安装结构。</div>
                </li>

                <li>
                    <div class="txt"><em>显示组合</em></div>
                    <div class="msg">车行横洞、人行横洞、案急停车带、紧急电话、消防、紧急疏散等指示标志</div>
                </li>
                <li>
                    <div class="txt"><em>输入电压</em></div>
                    <div class="msg">AC220V±15%，50Hz±3Hz</div>
                </li>

                <li>
                    <div class="txt"><em>MTBF</em></div>
                    <div class="msg">≥30000小时</div>
                </li>
                <li>
                    <div class="txt"><em>MTTR</em></div>
                    <div class="msg">≤0.5小时</div>
                </li>

                

            </ul>
        </div>

        <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
            <el-button @click="goBack">返回列表</el-button>
        </div>


    </div>




</template>
<script>


    export default {
        methods: {
            goBack() {
  this.$router.push({ path: '/product-center', query: { tab: 'four' }});
  const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

}
    
        ,

            //       goTo(){
            //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
            //     const windowHeight = window.innerHeight; // 
            //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
            //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
            //     window.scrollTo(0, scrollTo); //
            //   },
        }
    }




</script>

<style scoped>
    .name {
        padding-bottom: .1042rem;
        border-bottom: #eeeeee solid .0052rem;
        font-size: .1042rem;
        color: #2056c0;
        font-weight: bold;
        position: relative;
    }

    .msgList {
        padding-top: .2604rem;

        border-bottom: #eee solid .0052rem;
    }

    .msgList li {
        border: #c6c6c6 solid .0052rem;
        padding: .0521rem .1042rem .0521rem 1.0938rem;
        position: relative;
        font-size: .0729rem;
        margin-bottom: .0521rem;
        text-align: center;
        line-height: .2604rem;
    }

    li {
        list-style-position: outside;
        list-style-type: none;

    }

    .msgList li .msg {
        line-height: .1667rem;
        color: #999;
    }

    .content {
        padding-top: .1563rem;
        font-size: .0729rem;
        line-height: .125rem;
        color: #434343;

    }

    .tel {
        padding-top: .0521rem;
        font-size: .1875rem;
        color: #bb3944;
        font-family: Impact;
    }

    .txt {
        position: absolute;
        left: 0rem;
        top: 0rem;
        width: .9896rem;
        height: 100%;
        text-align: center;
        color: #FFFFFF;
        background: #2056c0;
        font-size: .0729rem;
        font-family: Arial, "微软雅黑";

    }

    .tittle {

        margin-top: .5208rem;
        height: .2083rem;
        text-align: center;
        line-height: .2083rem;
        font-weight: bold;
        font-size: .0833rem;
        color: #fff;

        position: relative;
    }
</style>