<template>

    <div>
    <div style="font-family: Arial, '微软雅黑';font-size:.0729rem; color: #999;padding-top: .1042rem;
    line-height: .125rem;">
      <P style="font-size: .125rem;width: 100%;flex-wrap: wrap;margin-top: .3125rem;
      color: #333;
      text-align: center;">丰海科技被认定为广州开发区瞪羚企业
      </P>
  
      <p style="width: 100%;
      text-align: center;font-size: .0625rem;
      margin-top: .0781rem;"> 2016-01-25
      </p>
  
      <div style="width:6.5104rem;margin: 0 auto;padding-bottom: .0781rem;border-bottom: #eee solid .0052rem;">
      </div>
  
  
  
      <div style="width:6.5104rem;color: #666666;font-size: .0833rem;font-family: 'HarmonyOS_Sans_SC_Regular';margin: 0 auto;padding-top: .1302rem;padding-bottom: .6771rem;border-bottom: #eee solid .0052rem;position: relative;">
        <p style="text-indent: .1563rem;">
          
            2016年1月21日，公司收到广州经济开发区科技创新和知识产权局下发的《关于认定广州开发区2015年度瞪羚企业的通知》，公司被认定为广州开发区2015年度瞪羚企业，这是对公司创新发展、快速增长的认可及肯定。
           
        </p>
    
  
        <p style="text-indent: .1563rem;">
            认定为瞪羚企业后，公司将获得更多的产业政策支持，包括创新投入、市场开拓、人才引进、融资上市等方面的政策支持，对公司今后发展有着积极的影响。公司将以此为契机，不断提升企业核心竞争力，力争取得更快更好的发展

 
         
        </p>
  
        <div style="position: absolute; bottom: 0;right: 0;">
          <div class="social-share" data-initialized="true">
            <span style="margin-right: .0521rem;">分享到:</span>
            <a href="http://www.weibo.com" class="social-share-icon icon-weibo"></a>
            <a href="#" class="social-share-icon icon-qq"></a>
            <a href="#" class="social-share-icon icon-qzone"></a>
            <a href="#" class="social-share-icon icon-wechat"></a>
            
        </div>
  
        </div>
  
      </div>
      
  
      <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem;">
        <el-button @click="goBack">返回列表</el-button>
      </div>
  
    </div>
     
    </div>
  </template>
  
  <script>
  
  
    export default {
      methods: {
      goBack() {
        history.back();
      }
    }
  }
  
  
  
  
  </script>