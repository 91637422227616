<template>
  <div>
    <div :style="{ width: '100%', height: '3.3333rem', backgroundImage: 'url(' + require('../assets/解决方案12345.webp') + ')', backgroundSize: '100% 100%' }">
    </div>
    <!-- 轮播图部分 -->
      
    <router-view> </router-view>




    <!--  -->

  </div>
</template>

<script>


  export default {
    name: 'soluTion',
    components: {

    },
    data() {
      return {

      };
    },
    methods: {
      changeTa(event) {
        const cor = event.currentTarget.querySelector('i');
        cor.style.color = "#407BF5";
        const img = event.currentTarget.querySelector('img');
        img.style.transition = "0.6s"; // 设置动画过渡时间为0.5秒
        img.style.transform = "scale(1.2)"; // 设置图片放大1.2倍
        const opo = event.currentTarget.querySelector('.firP');
        opo.style.fontSize = 25 + "px";
        opo.style.transition = "0.6s";

        const opoT = event.currentTarget.querySelector('.seodP');
        opoT.style.fontWeight = "bold";
        opoT.style.transition = "0.6s";

      },
      changeOut(event) {
        const cor = event.currentTarget.querySelector('i');
        cor.style.color = "gray"
        const img = event.currentTarget.querySelector('img');
        img.style.transition = "0.5s"; // 设置动画过渡时间为0.5秒
        img.style.transform = "scale(1)"; // 设置图片放大1.2倍
        const opo = event.currentTarget.querySelector('.firP');
        opo.style.fontSize = 18 + "px";
        opo.style.transition = "0.5s";

        const opoT = event.currentTarget.querySelector('.seodP');
        opoT.style.fontWeight = null;
        opoT.style.transition = "0.5s";

      },

      chaImg(event) {
        const img = event.currentTarget.querySelector('img');
        img.style.transition = "0.5s"; // 设置动画过渡时间为0.5秒
        img.style.transform = "scale(1.2)"; // 设置图片放大1.2倍
        img.style.overflow = "hidden"; // 超出部分隐藏
        const pl = event.currentTarget.querySelector('p');
        pl.style.transition = "0.2s";
        pl.style.fontSize=18+"px"
        pl.style.background = "linear-gradient(180deg, rgba(64, 123, 245, 0.8) 0%, rgba(42, 98, 200, 0.8) 100%)"; 
        
       

      },
      chaoutImg(event) {
        const img = event.currentTarget.querySelector('img');
        img.style.transition = "0.5s"; // 清除过渡效果
        img.style.transform = ""; // 清除变换效果
        img.style.overflow = ""; // 清除overflow属性
        const pl = event.currentTarget.querySelector('p');
        
        pl.style.opacity = "1"; 
        pl.style.fontSize=16+"px";
        pl.style.transition = "0.4s";
        pl.style.background ="linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%)"
      }

    }
  };

</script>


<style scoped>
  h1 {
    color: blue;
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #407BF5;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: gray;
  }

  @font-face {
    font-family: 'PingFang SC, PingFang SC';
    src: url('../assets/font/PingFang Regular.ttf') format('truetype');
   
  }
</style>