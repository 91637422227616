<template>
    <div>
      <div style="width: 6.5104rem;margin: 0 auto;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/product-center', query: { tab: 'first' }}">交通雷达</el-breadcrumb-item>
          <el-breadcrumb-item>边缘计算服务器</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
  
      <div style="display: flex;justify-content: center;margin-top: .4167rem;">
        <div  style="display: inline-block;transform: scale(1.05) translateX(-0.6771rem);">
          <img src="../../assets/009.webp" alt="" style="">
        </div>
  
        <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
          <p class="name">边缘计算服务器</p>
          <P class="content" style="text-indent: .1198rem;font-size: .0833rem">边缘计算服务器是一种分布式计算架构，旨在将计算资源和数据处理靠近数据源，提供低延迟、高可靠性和高安全性的计算服务。与传统云计算不同，边缘计算服务器将计算任务转移到靠近数据源的边缘节点上处理。搭载NVIDIA Jetson Xavier NX模块，该设备在15W低功耗下提供21Tops的实时推理能力，具备小巧尺寸、丰富接口和灵活扩展性，适合应用于智慧交通、智能机器人等领域。边缘计算服务器是一种分布式计算架构，旨在将计算资源和数据处理靠近数据源，提供低延迟、高可靠性和高安全性的计算服务。与传统云计算不同，边缘计算服务器将计算任务转移到靠近数据源的边缘节点上处理。搭载NVIDIA Jetson Xavier NX模块，该设备在15W低功耗下提供21Tops的实时推理能力，具备小巧尺寸、丰富接口和灵活扩展性，适合应用于智慧交通、智能机器人等领域。
          </P>
          <div style="margin-top: .2604rem;">
            <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
              <icon class="el-icon-phone-outline"></icon>技术热线
            </div>
            <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
  
          </div>
  
  
        </div>
  
  
  
      </div>
  
      <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
        详细参数</div>
  
      <!--  style="width: 6.5104rem;margin: 0 auto;"-->
      <div class="msgList" style=" width: 6.5104rem; margin: 0 auto;">
        <ul>
          <li>
            <div class="txt"><em>CPU</em></div>
            <div class="msg">6核NVIDIA Carmel ARM®v8.2 64位CPU 6MB L2 + 4MB L3</div>
          </li>
          <!-- ≥   ≤ -->
          <li>
            <div class="txt"><em>GPU</em></div>
            <div class="msg">具有48个Tensor内核的384核NVIDIA VoltaTM GPU</div>
          </li>
          <li>
            <div class="txt"><em>系统内存</em></div>
            <div class="msg">8 GB 128位LPDDR4x @ 1600 MHz 51.2GB/s</div>
          </li>
          <li>
            <div class="txt"><em>存储</em></div>
            <div class="msg">16 GB eMMC 5.1</div>
          </li>
          <li>
            <div class="txt"><em>显示接口</em></div>
            <div class="msg">HDMI Type A x1</div>
          </li>
          <li>
            <div class="txt"><em>以太网</em></div>
            <div class="msg">GbE局域网x 2(GbE PD x1+GbE局域网x 1)</div>
          </li>
          <li>
            <div class="txt"><em>I/O</em></div>
            <div class="msg">Gigabit Ethernet *2,RS232/422/485*1，GPIO*2，MIPI(CSI)*2,USB3.0*2，HDMI*1，30-pin I/0x1(GPI0/12S/SPI/UART)</div>
            
          </li>

          <li>
            <div class="txt"><em>指示灯</em></div>
            <div class="msg">电源指示灯x1</div>
            
          </li>

          <li>
            <div class="txt"><em>操作系统支持</em></div>
            <div class="msg">Linux( Ubantu 18.04)</div>
            
          </li>

          <li>
            <div class="txt"><em>电力需求</em></div>
            <div class="msg">12~24Vdc w/2针端子</div>
            
          </li>

          <li>
            <div class="txt"><em>尺寸</em></div>
            <div class="msg">189*96.7*57.5mm</div>
          </li>

          <li>
            <div class="txt"><em>重量</em></div>
            <div class="msg">1.2kg</div>
          </li>

          <li>
            <div class="txt"><em>防护等级</em></div>
            <div class="msg">IP50</div>
          </li>

          <li>
            <div class="txt"><em>工作温度</em></div>
            <div class="msg">-25°C~60°C满载运行 ;60°C~70°C降频运行</div>
          </li>

          <li>
            <div class="txt"><em>储存湿度</em></div>
            <div class="msg">5-95%@40°C,无凝结</div>
          </li>

          <li>
            <div class="txt"><em>认证</em></div>
            <div class="msg">CCC/ROSH/SRRC</div>
          </li>
       
        </ul>
      </div>
  
  
      <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
        <el-button @click="goBack">返回列表</el-button>
      </div>
  
  
    </div>
  
  
  
  
  </template>
  <script>
  
  
    export default {
      methods: {
        goBack() {
  this.$router.push({ path: '/product-center', query: { tab: 'first' }});
  const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

}
        ,
  
        //       goTo(){
        //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
        //     const windowHeight = window.innerHeight; // 
        //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
        //     window.scrollTo(0, scrollTo); //
        //   },
      }
    }
  
  
  
  
  </script>
  
  <style scoped>

    .name {
      padding-bottom: .1042rem;
      border-bottom: #eeeeee solid .0052rem;
      font-size: .1042rem;
      color: #2056c0;
      font-weight: bold;
      position: relative;
    }
  
    .msgList {
      padding-top: .2604rem;
      border-bottom: #eee solid .0052rem;
    }
  

    .msgList li {
      border: #c6c6c6 solid .0052rem;
      padding: .0521rem .1042rem .0521rem 1.0938rem;
      position: relative;
      font-size: .0729rem;
      margin-bottom: .0521rem;
      text-align: center;
      line-height: .2604rem;
    }

  
    li {
      list-style-position: outside;
      list-style-type: none;
    }

  
    .msgList li .msg {
      line-height: .1667rem;
      color: #999;
    }
  
    .content {
      padding-top: .1563rem;
      font-size: .0729rem;
      line-height: .125rem;
      color: #434343;
  
    }
  
    .tel {
      padding-top: .0521rem;
      font-size: .1875rem;
      color: #bb3944;
      font-family: Impact;
    }
  
    .txt {
      position: absolute;
      left: 0rem;
      top: 0rem;
      width: .9896rem;
      height: 100%;
      text-align: center;
      color: #FFFFFF;
      background: #2056c0;
      font-size: .0729rem;
      font-family: Arial, "微软雅黑";
  
    }
  
    .tittle {
  
      margin-top: .5208rem;
      height: .2083rem;
      text-align: center;
      line-height: .2083rem;
      font-weight: bold;
      font-size: .0833rem;
      color: #fff;
  
      position: relative;
    }
  </style>
  <!--  
  有一个轮播图, 




   -->