<template>
    <div>
        <div class="newtre" style="width:100%;display: flex;justify-content: center;background-color: #E9E9E9;margin-bottom: 0px;">
            <div class="opp" style="width:7.2865rem;font-size: .0938rem;margin-top: .1042rem;">
                <el-tabs v-model="activeName" type="border-card" style="position: relative;padding-bottom: .0521rem;">
                    <el-tab-pane label="公司新闻" name="first">
                        <ul class="list">
                            <li v-for="(item, index) in filteredItemList" :key="index" @click="goToSeparateNews(item)">
                                <div class="item-container">
                                    <div class="item-title">
                                        <p class="frp"></p>
                                        <p
                                            style="font-size: .1458rem;margin-top: .026rem; line-height: .1823rem;text-indent: .1563rem;">
                                            {{ item.title }}</p>
                                        <p
                                            style="margin-top: .026rem; font-size: .0729rem;text-indent: .1563rem;color: rgb(153, 153, 153);">
                                            {{ item.date }}</p>
                                        <p
                                            style="margin-top: .0521rem; font-size: .0938rem; line-height: .1042rem; text-indent:.2708rem; width: 5.2083rem; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                            {{ item.text }}</p>
                                    </div>
                                    <div class="item-image" style="height:.7813rem;width: 1.5625rem;">
                                        <img :src="item.image" alt="图片" style="width: 100%; height: 100%;">
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <el-pagination background layout="prev, pager, next" :total="totalItems"
                            :current-page="currentPage" @current-change="handlePageChange"
                            style="position: absolute; left:50%; transform: translateX(-50%); bottom: .026rem">
                        </el-pagination>
                    </el-tab-pane>
                    <el-tab-pane name="second" label="行业动态">行业动态</el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div style="width: 100%;height: .1563rem;background-color: #E9E9E9;"></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                activeName: "first",
                itemList: [
                {  
                        title: "丰海科技亮相第二十六届高速公路信息化大会",
                        date: "2024-03-28",
                        text: "3月28日，由中国公路学会举办的第二十六届高速公路信息化大会在合肥滨湖国际会展中心隆重开幕。丰海科技积极响应本次大会“创新引领、数字转型、智慧升级”的主题，携众多优秀产品和创新方案深度参与此次大会，吸引了大量部门、机构及企业负责人前来探展洽谈。",
                        image: require('@/assets/zhanhui1.png'),
                        separateNews: "separateNews25",
                    },
                {  
                        title: "鸣金收兵，丰海科技团队荣获第二届粤港澳大湾区（黄埔）国际算法算例大赛冠军！",
                        date: "2024-01-12",
                        text: "在第二届粤港澳大湾区（黄埔）国际算法算例大赛的《路侧毫米波雷达标定和目标跟踪》赛题中，丰海科技参赛团队表现优异，荣获冠军。",
                        image: require('@/assets/newspaper11.webp'),
                        separateNews: "separateNews24",
                    },
                    {  
                        title:"丰海科技正式成为美的集团供应商",
                        date: "2024-01",
                        text: "丰海科技顺利通过美的集团家用空调事业部专家现场审查，正式成为美的集团供应商，与美的集团展开深入合作。",
                        image:  require('@/assets/newspaper10.webp'),
                        separateNews: "separateNews23",
                    },
                    {  
                        title: "丰海科技全力保障深中通道顺利通车",
                        date: "2023-08",
                        text: "丰海科技为深中通道项目供货，供货范围包括：车道控制标志、交通信号灯、LED指示标志、雨棚信号灯等设备，助力深中通道实现智能交通管控；",
                        image:  require('@/assets/newspaper9.webp'),
                        separateNews: "separateNews22",
                    },
                     {  
                        title: "实干开新局，产线再升级！",
                        date: "2023-08",
                        text: "丰海科技升级SMT(表面组装技术)生产线，新增3DSPI、3DAOI设备，同时在原有DIP（双列直插式封装）车间基础上新增插件线，引进波峰焊设备，导入UV涂护工艺，从而提升订单交付能力。",
                        image: require('@/assets/newspaper7.webp'),
                        separateNews: "separateNews21",
                    },
                    
                    {  
                        title: "丰海科技荣膺国家级专精特新“小巨人”企业称号",
                        date: "2023-07-14",
                        text: " 凭借出色的技术创新能力与强大的市场占有率，丰海科技被工业和信息化部授予国家级专精特新“小巨人”企业称号。 ",
                        image: require('@/assets/newspaper5.webp'),
                        separateNews: "separateNews20",
                    },
                    {  
                        title: "雷视融合系统助力实现智慧交通",
                        date: "2023-06",
                        text: "  丰海科技实现雷视融合技术的独立应用，将毫米波雷达和摄像头视频数据融合匹配，对目标进行精准检测与跟踪。",
                        image: require('@/assets/newspaper3.webp'),
                        separateNews: "separateNews19",
                    },
                    {
                        title: "创新引领发展，丰海科技再获新专利 ",
                        date: "2023-05-30",
                        text: " 丰海科技荣获 《一种基于通信感知多源数据融合的交通参数检测系统及方法》发明专利证书。",
                        image: require('@/assets/newspaper21.webp'),
                        separateNews: "separateNews18",
                    },

                    {
                        title: "丰海科技自主研发产品“毫米波雷达”正式投入市场",
                        date: "2023-05-10",
                        text: "  丰海科技完成从埔高速项目供货，其中包含丰海科技自主研发产品“毫米波雷达”。此举标志着毫米波雷达正式投入市场，成功实现首次应用。 ",
                        image: require('@/assets/newspaper1.webp'),
                        separateNews: "separateNews17",
                    },
                    {
                        title: "丰海科技与你相约PWTC展会",
                        date: "2017-08-06",
                        text: "2017年7月底，我司国际业务部与研发中心参加吉隆坡PWTC展会，使我司产品真正实现走出去。公司此次参展以拓宽视野、开启思路、学习先进、交流合作为主，充分利用本次参展机会与前来参观的客户和经销商进行交流、沟通、洽谈，进一步提升了公司品牌的知名和影响力，同时也进一步了解同行先进企业的产品特点，以便更好地完善自身产品结构，发挥自身优势。",
                        image: require('@/assets/zh1.jpg'),
                        separateNews: "separateNews1",
                    },

                    {
                        title: "成立海外业务部，进军国际市场",
                        date: "2017-06-06",
                        text: " 5月底，广州市丰海科技股份有限公司海外业务部正式成立，这是我们在“走出国门, 布局海外”的道路上迈出的第一步，成立海外业务部也是公司创新发展模式、提升竞争能力的迫切要求。 ",
                        image: "",
                        separateNews: "separateNews2",
                    },
                    {
                        title: "丰海科技“梅开二度” 入选2017新三板创新层",
                        date: "2017-06-06",
                        text: "   全国中小企业股份转让系统于2017年5月30日发布2017年《创新层挂牌公司名单》，2016年新三板创新层企业丰海科技(股票代码：830862)再次成功入选创新层，并成为新三板智能交通领域唯一一家、连续两年入选创新层的品牌实力企业。",
                        image: "",
                        separateNews: "separateNews3",
                    },
                    {
                        title: "转型升级，打通上下游产业链",
                        date: "2016-09-06",
                        text: " 2016年8月，公司完成重大资产重组，收购下游集成商天津市高速公路科技发展有限公司44.10%的股权，正式成为高速科技第二大股东。",
                        image: "",
                        separateNews: "separateNews4",
                    },
                    {
                        title: "丰海科技荣获新三板最具价值投资百强奖",
                        date: "2016-08-01",
                        text: "2016年7月，由中国证券报发起并组织评选的“2015年度中国新三板金牛奖”公布获奖名单，丰海科技从当时九千多家新三板企业中脱颖而出，荣获“新三板最具价值投资百强奖”。",
                        image: "",
                        separateNews: "separateNews5",
                    },
                    {
                        title: "广州开发区管委会秘书长陈建荣一行莅临公司参观指导",
                        date: "2016-07-12",
                        text: "2016年7月7日，广州开发管委会秘书长陈建荣一行多人到丰海科技参观指导，公司董事长胡亚平及财务总监向道庆接待了来访贵宾。",
                        image: "",
                        separateNews: "separateNews6",
                    },
                    {
                        title: "新三板分层落地，丰海科技首批进入创新层",
                        date: "2016-06-29",
                        text: "根据全国中小企业股份转让系统2016年6月24日发布的《关于正式发布创新层挂牌公司名单的公告》（股转系统公告【2016】50号），共有953家新三板公司入选创新层，广州市丰海科技股份有限公司（公司简称：丰海科技，证券代码：830862）从7500多家挂牌企业中脱颖而出，首批进入了创新层。分层制度被看作是2016年新三板最受关注的政策红利，分层制度的推出标志着新三板市场进入了一个全新的发展阶段。",
                        image: "",
                        separateNews: "separateNews7",
                    },
                    {
                        title: "齐聚金陵，参加第十八届高速公路技术产品展",
                        date: "2015-03-30",
                        text: " 第十八届中国高速公路信息化研讨会暨技术产品展示会于2016年3月24—25日在南京国际展览中心隆重举行，吸引了来自全国各地200多家厂商参展，丰海科技应邀参展",
                        image: "",
                        separateNews: "separateNews8",
                    },
                    {
                        title: "丰海科技被认定为广州开发区瞪羚企业",
                        date: "2016-01-25",
                        text: " 2016年1月21日，公司收到广州经济开发区科技创新和知识产权局下发的《关于认定广州开发区2015年度瞪羚企业的通知》，公司被认定为广州开发区2015年度瞪羚企业，这是对公司创新发展、快速增长的认可及肯定。",
                        image: "",
                        separateNews: "separateNews9",
                    },
                    {
                        title: "广东新三板公司协会正式成立，丰海科技成功当选副会长单位",
                        date: "2015-09-18",
                        text: " 2015年9月16日下午,广东新三板公司协会第一届会员大会暨第一期新三板公司高管培训班在广州东方宾馆一楼会展A厅隆重举行。广州市丰海科技科技股份有限公司董事长胡亚平应邀出席会议，丰海科技成功当选副会长单位。",
                        image: "",
                        separateNews: "separateNews10",
                    },
                    {
                        title: "热烈祝贺丰海科技被认定为“高新技术企业”",
                        date: "2015-04-06",
                        text: "  2015年4月2日，我公司收到广东省科学技术厅、广东省财政厅、广东省国家税务局、广东省地方税务局联和下发的粤科高字（2015）第30号《高新技术企业名单的通知》，认定公司为2014年高新技术企业，证书编号：GR201444001363，有效C期三年。",
                        image: "",
                        separateNews: "separateNews11",
                    },

                    {
                        title: "三亚欢乐游",
                        date: "2015-03-31",
                        text: "  2015年3月25日-28 日，丰海科技举行了为期四天的“三亚欢乐游”活动。此次活动不仅让大家饱览了海南岛的大好风光，也增进了同事之间的感情。 ",
                        image: "",
                        separateNews: "separateNews12",
                    },
                    {
                        title: "丰海科技应邀参加第十七届中国高速公路信息化研讨会暨技术产品展示会",
                        date: "2015-03-23",
                        text: "  2015年3月19至20日,广州市丰海科技股份有限公司参加了在南昌举办的第十七届中国高速公路信息化研讨会暨技术产品展示会。此次展会的主题是“高速公路信息化与智慧交通”。 展会上，丰海科技展示了可变情报板、LED显示标志等多套技术产品，并与客商、业主和同行代表进行洽谈。",
                        image: "",
                        separateNews: "separateNews13",
                    },
                    {
                        title: "热烈祝贺丰海科技（830862）股票开始做市交易",
                        date: "2015-01-17",
                        text: "  经过“全国中小企业股份转让系统“同意，丰海科技（830862）股票于2015年1月14日正式开始做市转让",
                        image: "",
                        separateNews: "separateNews14",
                    },
                    {
                        title: "热烈祝贺丰海科技成功登陆“新三板”",
                        date: "2014-07-18",
                        text: " 2014年7月15日，广州市丰海科技股份有限公司在“全国中小企业股份转让系统”（新三板）挂牌上市，这标志着丰海科技正式步入资本市场。证券简称：丰海科技，证券代码：830862。 ",
                        image: "",
                        separateNews: "separateNews15",
                    },
                    {
                        title: "难忘之旅-记阳江闸坡自由岛拓展旅游活动",
                        date: "2014-05-20",
                        text: "  5月15日-5月17日，丰海科技在成立7周年之际举办了为期三天的“闸坡自由岛拓展旅游”活动。 ",
                        image: "",
                        separateNews: "separateNews16",
                    },
                    // 列表项内容
                ],
                totalItems: 50, // 列表项总数
                currentPage: 1, // 当前页码
                itemsPerPage: 6, // 每页显示的列表项数量
            };
        },
        mounted() {
            this.handleTabChange(this.$route.query.tab);
        },
        watch: {
            '$route.query.tab': {
                immediate: true,
                handler(newTab) {
                    this.handleTabChange(newTab);
                }
            }
        },
        methods: {
            handleTabChange(tab) {
                if (tab === 'first') {
                    this.activeName = 'first'; // 设置第一个选项卡为激活状态
                } else if (tab === 'second') {
                    this.activeName = 'second'; // 设置第二个选项卡为激活状态
                }
                console.log(tab); // 打印传递过来的 tab 参数
            },
            handlePageChange(page) {
                this.currentPage = page;
            },
            goToSeparateNews(item) {
                this.$router.push({ path: item.separateNews });
            },
        },
        computed: {
            filteredItemList() {
                const start = (this.currentPage - 1) * this.itemsPerPage;
                const end = start + this.itemsPerPage - 1;
                return this.itemList.filter((item, index) => index >= start && index <= end);
            },
        },
    };
</script>

<style scoped>
    .list {
        text-decoration: none;
        list-style: none;
        padding-bottom: 20px;
    }

    .list li {
        width: 100%;
        height: .7813rem;
        background-color: #e9e9e9;
        margin-bottom: .0781rem;
        font-family: HarmonyOS_Sans_SC_Regu平方lar;
    }

    .list li:hover p:nth-child(2) {
        color: #407BF5;
    }

    li:hover p:first-child {
        background-color: #407BF5;
    }


    .item-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
    }

    .opp /deep/ .el-tabs__nav-scroll {
        background-color: #E9E9E9;

    }

    .frp {
        background-color: #E9E9E9;
        width: 20px;
        height: 20px;
    }

    .title-container {
        flex-basis: 100%;
    }

    .item-title {
        /* 标题样式 */
    }

    .opp /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
        border-right-color: #407BF5;
    }

    .opp /deep/ .el-tabs--border-card {
        border: none;
        box-shadow: none;
    }

    .item-image {
        margin-left: auto;
    }

    .item-container::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;

    }

    @media screen and (max-width: 1425px) {
        .newtre{
     transform: scale(0.90);

  
    
    }
    .item-image{
    position: absolute;
    right: 0;
    opacity: 0.6; /* 增加50%的透明度 */
    

    }
  
}

@media screen and (min-width: 1426px) and (max-width: 1510px) {
  .newtre{
     transform: scale(0.90);
    }
}
</style>