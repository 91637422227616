<template>
  <div >
      <div
          :style="{  width: '100%',  marginTop: '.2604rem',height: '3.3333rem',backgroundImage: 'url(' + require('../assets/new123.webp') + ')', backgroundSize: '100% 100%' }">
      </div>
      <!-- 轮播图部分 -->

      <!-- <div style="width: 1250px;margin: 0 auto;">
          <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/product-center'}">新闻中心</el-breadcrumb-item>
              
             
          </el-breadcrumb>
      </div> -->

      <div
          style="width: 100%; height: .6823rem; display: flex; justify-content: center; align-items: center; position: relative;">
          <!-- <img style="margin: 0 auto;position: absolute;" src="../assets/total1.png" alt=""> -->
          <P style="width: 1.4896rem;
          height: .1875rem;
          font-family: Arial, Arial;
          font-weight: 400;
          font-size: .1667rem;
          color: #1c4ef02b;
          line-height: 0rem;
          text-align: left;
          font-style: normal;
          text-transform: none;transform: translateY(.1094rem)  translateX(.1875rem);">News &nbsp;&nbsp;Center</P>
          <p
              style="font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0938rem;">
              新闻中心</p>
          <p
              style=" display: inline-block; height: .0104rem; width: .3333rem;background-color:  #407BF5;;font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0417rem;">
          </p>
      </div>

      <router-view></router-view>

   

  </div>

</template>

<script>
  export default {

}
</script>



<style scoped>
  .list{
      text-decoration: none;list-style: none;padding-bottom: 20px;
  }
.list li{
  width: 100%;   height: 150px;  background-color: #e9e9e9;margin-bottom: 15px;
}

li:hover p:first-child{
color: #407BF5;
}


.item-container {
display: flex;
justify-content: space-between;
flex-wrap: wrap;
position: relative;
}
.opp /deep/ .el-tabs__nav-scroll {
  background-color: #E9E9E9 ;  
 
}

.title-container {
flex-basis: 100%;
}

.item-title {
/* 标题样式 */
}
.opp /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  border-right-color:#407BF5 ;
}

.opp /deep/ .el-tabs--border-card {
  border: none;
  box-shadow: none;
}

.item-image {
margin-left: auto;
}

.item-container::before {
content: "";
position: absolute;
top: 0;
left: 0;
width: 50%;
height: 100%;

}

</style>