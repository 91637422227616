<template>
    <div>
      <div :style="{ width: '100%', height: '3.3333rem', backgroundImage: 'url(' + require('../assets/案例展示2.webp') + ')', backgroundSize: '100% 100%' }">
      </div>
      
      <div
      style=" width: 100%; height: .6823rem; display: flex; justify-content: center; align-items: center; position: relative;">
     
      <img src="../assets/案例展示.png" alt="" style="height: .1875rem;">
      
      <p
        style="font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .1042rem;">
        案例展示
      </p>
 
      <!-- boxShadow: '-0.0521rem .1563rem .1042rem .0521rem rgba(139.5, 133.75, 112, 0.8)', -->
      <p
        style=" display: inline-block; height: .0104rem; width: .3333rem;background-color:  #407BF5;;font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0521rem;">
      </p>

    

      
    </div>
      <!-- 轮播图部分 -->
        <router-view> </router-view>
        <!--  -->

      <!--  -->
  
    </div>
  </template>
  
  <script>
  
  
    export default {
      data() {
        return {
  
        };
      },
      methods: {
        changeTa(event) {
          const cor = event.currentTarget.querySelector('i');
          cor.style.color = "#407BF5";
          const img = event.currentTarget.querySelector('img');
          img.style.transition = "0.6s"; // 设置动画过渡时间为0.5秒
          img.style.transform = "scale(1.2)"; // 设置图片放大1.2倍
          const opo = event.currentTarget.querySelector('.firP');
          opo.style.fontSize = .1302 + "rem"; 
          opo.style.transition = "0.6s";
  
          const opoT = event.currentTarget.querySelector('.seodP');
          opoT.style.fontWeight = "bold";
          opoT.style.transition = "0.6s";
  
        },
        changeOut(event) {
          const cor = event.currentTarget.querySelector('i');
          cor.style.color = "gray"
          const img = event.currentTarget.querySelector('img');
          img.style.transition = "0.5s"; // 设置动画过渡时间为0.5秒
          img.style.transform = "scale(1)"; // 设置图片放大1.2倍
          const opo = event.currentTarget.querySelector('.firP');
          opo.style.fontSize = .0938 + "rem"; 
          opo.style.transition = "0.5s";
          const opoT = event.currentTarget.querySelector('.seodP');
          opoT.style.fontWeight = null;
          opoT.style.transition = "0.5s";
  
        },
  
        chaImg(event) {
          const img = event.currentTarget.querySelector('img');
          img.style.transition = "0.5s"; // 设置动画过渡时间为0.5秒
          img.style.transform = "scale(1.2)"; // 设置图片放大1.2倍
          img.style.overflow = "hidden"; // 超出部分隐藏
          const pl = event.currentTarget.querySelector('p');
          pl.style.transition = "0.2s";
          pl.style.fontSize=.0938+"rem";
          pl.style.background = "linear-gradient(180deg, rgba(64, 123, 245, 0.8) 0%, rgba(42, 98, 200, 0.8) 100%)"; 
          
         
  
        },
        chaoutImg(event) {
          const img = event.currentTarget.querySelector('img');
          img.style.transition = "0.5s"; // 清除过渡效果
          img.style.transform = ""; // 清除变换效果
          img.style.overflow = ""; // 清除overflow属性
          const pl = event.currentTarget.querySelector('p');
          
          pl.style.opacity = "1"; 
          pl.style.fontSize=.0833+"rem"; 
          pl.style.transition = "0.4s";
          pl.style.background ="linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%)"
        }
  
      }
    };
  
  </script>
  
  
  <style scoped>
    h1 {
      color: blue;
    }
  
    .el-carousel__item h3 {
      color: #475669;
      font-size: .0729rem;
      opacity: 0.75;
      line-height: 1.0417rem;
      margin: 0;
    }
  
    .el-carousel__item:nth-child(2n) {
      background-color: #407BF5;
    }
  
    .el-carousel__item:nth-child(2n+1) {
      background-color: gray;
    }

    .contain /deep/ .t-tabs__nav-item.t-size-m {
    font-size: .0938rem;
    font-family: 'HarmonyOS_Sans_SC_Regular';
    font-weight: bold;
  }


  </style>