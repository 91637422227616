<template>
  <div class="mainhh">
    <div class="box" v-show="isDesktop" :style="{ backgroundColor: boxBackgroundColor,color:boxColor}">
      <img :src="logoSrc" alt="" class="logo">

      <ul class="centered-ul" :style="{ backgroundColor: boxBackgroundColor}">
        <router-link style="text-decoration: none;" to="/PageHome">
          <li :style="{ color: centerColor  }" :class="{ 'centered-li': true, 'default': activeIndex === 0 }"
            @click="changeColor(0)" data-index="0">首页</li>
        </router-link>

        <router-link style="text-decoration: none;" to="/aboutUs">
          <li class="centered-li" :style="{ color: centerColor  }" :class="{ 'default': activeIndex === 4 }"
            @click="changeColor(4)" @mouseenter="showFh = true" @mouseleave="showFh = false">

            关于丰海

            <ul class="dropdown-menu" :class="{ show: showFh }">

              <li @click="goToNew8">   公司简介  </li>
              <li @click="goToNew9">   文化理念  </li>
              <li @click="goToNew10">  荣誉资质  </li>
              <li @click="goToNew11">  发展历程  </li>
              <li @click="goToNew12">  组织架构  </li>
            </ul>
          </li>
        </router-link>

        <!-- 新添加的 -->
        <router-link style="text-decoration: none;" to="/newsCentres">
          <li class="centered-li" :style="{ color: centerColor }" :class="{ 'default': activeIndex === 6 }"
            @click="changeColor(6)" @mouseenter="showCenter = true" @mouseleave="showCenter = false">
            新闻中心
            <ul class="dropdown-menu" :class="{ show: showCenter }">
              <li @click="goToNew6"> 公司新闻 </li>
              <li @click="goToNew7"> 行业动态 </li>
            </ul>
          </li>
        </router-link>

        <router-link style="text-decoration: none;" to="/product-center">
          <li class="centered-li" :style="{ color: centerColor }" :class="{ 'default': activeIndex === 1 }"
            @click="changeColor(1)" @mouseenter="showPro = true" @mouseleave="showPro = false">
            产品中心
            <ul class="dropdown-menu" :class="{ show: showPro }">
              <li @click="goToPct1"> 交通雷达 </li>
              <li @click="goToPct2"> 通信模组 </li>
              <li @click="goToPct3"> 收费亭   </li>
              <li @click="goToPct4"> 交通诱导 </li>
              <li @click="goToPct5"> 情报板   </li>
              <li @click="goToPct6"> 车道设备 </li>
              <li @click="goToPct7"> 显示标志 </li>
              <li @click="goToPct8"> LED照明  </li>
            </ul>
          </li>
        </router-link>

        <!--  -->
        <router-link style="text-decoration: none;" to="/casePresentation">
          <li class="centered-li" :style="{ color: centerColor }" :class="{ 'default': activeIndex === 7 }"
            @click="changeColor(7)" @mouseenter="showMor = true" @mouseleave="showMor = false">

            案例展示
            
            <ul class="dropdown-menu" :class="{ show: showMor }">
              <li @click="goToCase1">交通雷达</li>
              <li @click="goToCase2">通信模组</li>
              <li @click="goToCase3">收费亭</li>
              <li @click="goToCase4">交通诱导</li>
              <li @click="goToCase5">情报板</li>
              <li @click="goToCase6">车道设备</li>
              <li @click="goToCase7">显示标志</li>
              <li @click="goToCase8">LED照明</li>
            </ul>
          </li>
        </router-link>

        <!--  -->
        <router-link style="text-decoration: none;" to="/soluTion">
          <li class="centered-li" :style="{ color: centerColor }" :class="{ 'default': activeIndex === 2 }"
            @click="changeColor(2)" @mouseenter="showServe = true" @mouseleave="showServe = false">
            解决方案
            <ul class="dropdown-menu" :class="{ show: showServe }">
              <li> <router-link to="/samCov18" class="custom-router-link"> 智慧高速 </router-link></li>
              <li> <router-link to="/samCov17" class="custom-router-link"> 智慧家居 </router-link></li>
              <li> <router-link to="/samCov19" class="custom-router-link"> 智慧城市 </router-link></li>
              <li> <router-link to="/samCov14" class="custom-router-link"> 智慧排班 </router-link></li>
            </ul>
          </li>
        </router-link> 
        <router-link style="text-decoration: none;" to="/serviceSupport">
          <li class="centered-li" :style="{ color: centerColor }" :class="{ 'default': activeIndex === 3 }"
            @click="changeColor(3)" @mouseenter="showSup = true" @mouseleave="showSup = false">
            服务支持
            <ul class="dropdown-menu" :class="{ show: showSup }">
              <li @click="goToProductCenter3">下载中心</li>
              <li @click="goToProductCenter4">常见问题</li>
              <li @click="goToProductCenter5">技术热线</li>
            </ul>
          </li>
        </router-link>
        <router-link style="text-decoration: none;" to="/joinUs">
          <li class="centered-li" :style="{ color: centerColor }" :class="{ 'default': activeIndex === 5 }"
            @click="changeColor(5)" @mouseenter="showJoin = true" @mouseleave="showJoin = false">
           加入我们
            <ul class="dropdown-menu" :class="{ show: showJoin }">
              <router-link to="/justGo" class="custom-router-link">
                <li>加入丰海</li>
              </router-link>
             
              <li> <router-link to="/joinGo" class="custom-router-link">联系我们</router-link></li>
            </ul>
          </li>
        </router-link>
      </ul>

      <div class="kk">
        <div
          style="font-size: .0833rem; margin-top:.1302rem;width: .4167rem;font-weight: bold; font-family: PingFang SC, PingFang SC;"
          @click="goToProductCenter5">售后服务</div>
        <div
          style="font-size: .0833rem; margin-top: .1302rem;width:.4167rem; font-weight: bold; margin-right: .1823rem; font-family: PingFang SC, PingFang SC;"
          :style="{ color: textColor }" @click="changeTextColor">
          商用用户
        </div>

        <div class="search-icon">
          <img src="../assets/icons8-search-24.webp" alt="" @click="showModal"
            style="width:.1302rem;height: .1302rem;transform: translateY(.026rem);">
          <div class="modal" v-if="isModalVisible">
            <div class="modal-content">
              <span class="close" @click="hideModal"></span>
              <div class="modal-text">
                <p style="font-size: .1979rem;font-weight: 800;"> 我们能帮忙找点什么吗? </p>

                <div class="search-box" style="position: relative;margin: 0 auto;width: 2.6042rem;">
                  <div class="search-icon1" @click="contactPro"></div>
                  <input type="text" style="width: 2.6042rem; height: .1458rem; border: 30%; border-radius: .3125rem;
                     margin-top: .1042rem; outline: none; padding-left: .1563rem;padding-left: .2604rem;
                   
                     background-position: left center; background-repeat: no-repeat;
                      background-size: .1042rem;">
                </div>
                <div class="search-history">

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- 以上部分已使用flex 布局 -->


    <!--  这里是手机端的页面布局  -->
    <div class="mobile-navbar" v-show="!isDesktop" style="
    width: 10rem;
    height: 0.8333rem;
    background-color: #407BF5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: left;
    align-items: center;
  ">

      <img :src="logoSrc" alt="" class="logo" style="height: .4167rem; ">
      <i class="el-icon-s-fold" :class="{ active: isOpen }" @click="toggleMenu"
        style="margin-left: auto; margin-right: .3125rem; cursor: pointer; color: #FFF; scale: 1.2;"></i>

      <!-- 遮罩层 -->
      <div v-if="isOpen" class="overlay" @click="closeMenu"></div>

      <!-- 菜单栏 -->
      <transition name="slide">
        <div v-if="isOpen" class="menu">

          <i class="el-icon-close" @click="closeMenu"></i>


          <ul class="opl" style="display: flex;justify-content: column;">
            <li style="text-align: center;font-weight: bold;color: #000;">丰海科技</li>
            <el-divider></el-divider>
            <li @click="navigateTo('/PageHome')">丰海首页</li>
            <el-divider></el-divider>
            <li @click="navigateTo('/aboutUs')">关于丰海</li>
            <el-divider></el-divider>
            <li @click="navigateTo('/newsCentres')">新闻中心</li>
            <el-divider></el-divider>
            <li @click="navigateTo('/product-center')">产品中心</li>
            <el-divider></el-divider>
            <li @click="navigateTo('/casePresentation')">案例展示</li>
            <el-divider></el-divider>
            <li @click="navigateTo('/soluTion')">解决方案</li>
            <el-divider></el-divider>
            <li @click="navigateTo('/serviceSupport')">服务支持</li>
            <el-divider></el-divider>
            <li @click="navigateTo('/joinUs')">联系我们</li>
            <el-divider></el-divider>
          </ul>

        </div>

      </transition>
    </div>

    <t-space style="position: fixed; bottom: .1042rem; right: .1042rem;z-index: 999;">
      <t-space>
        <t-back-top style="position: relative;width: .3125rem;height: .3125rem;" :visible-height="0" size="medium"
          :offset="[0, 0]" v-show="showBackTop">
          <t-icon name="backtop" size="20" />
        </t-back-top>
      </t-space>
    </t-space>
    <!--  这是一个 置顶 的工具栏 窗口  -->

    <div>
      <router-view>
      </router-view>
    </div>

    <div>
      <div class="page6" style="display: flex;justify-content: center;align-items: center;">

        <img src="../assets/11350.webp" style="width:100%; height: 2.8594rem;">

        <div class="page9"
          style="width:6.25rem; height:.9375rem;top:.4323rem; position: absolute;display: flex;justify-content: space-between;">
          <ul>
            <li>首页</li>
          </ul>

          <ul>
            <li @click="goToProductCenter6">关于我们</li>
            <li @click="goToProductCenter6">公司简介</li>
            <li @click="goToProductCenter7">文化理念</li>
            <li @click="goToProductCenter77">研究中心</li>
            <li @click="goToProductCenter8">荣誉资质</li>
            <li @click="goToProductCenter10">发展历程</li>
            <li @click="goToProductCenter11">组织架构</li>
          </ul>

          <ul>
            <li @click="goToNew6">新闻中心</li>
            <li @click="goToNew6">公司新闻</li>
            <li @click="goToNew7">行业动态</li>
          </ul>

          <ul>
            <li @click="goToPct1">产品中心</li>
            <li @click="goToPct1">交通雷达</li>
            <li @click="goToPct2">雷达通信模组</li>
            <li @click="goToPct3">智能收费亭</li>
            <li @click="goToPct4">交通诱导系列</li>
            <li @click="goToPct5">可变情报板</li>
            <li @click="goToPct6">收费车道设备系列</li>
            <li @click="goToPct7">LED显示标志系列</li>
            <li @click="goToPct8">LED照明系列</li>
          </ul>

          <ul>
            <li @click="goToCase1">案例展示</li>
            <li @click="goToCase1">交通雷达</li>
            <li @click="goToCase2">雷达通信模组</li>
            <li @click="goToCase3">智能收费亭</li>
            <li @click="goToCase4">交通诱导系列</li>
            <li @click="goToCase5">可变情报板</li>
            <li @click="goToCase6">收费车道设备系列</li>
            <li @click="goToCase7">LED显示标志特列</li>
            <li @click="goToCase8">LED照明系列</li>
          </ul>

          <ul>
            <li @click="goToProductCenter2" @click.native="changeColor(2)">解决方案</li>
            <li> <router-link to="/samCov18" class="custom-rout5" @click.native="changeColor(2)">智慧高速</router-link></li>
            <li> <router-link to="/samCov17" class="custom-rout5" @click.native="changeColor(2)">智慧家居</router-link></li>
            <li> <router-link to="/samCov19" class="custom-rout5" @click.native="changeColor(2)">智慧城市</router-link></li>
            <li> <router-link to="/samCov14" class="custom-rout5" @click.native="changeColor(2)">智慧排班</router-link></li>
          </ul>

          <ul>
            <li @click="goToProductCenter5">服务支持</li>
            <li @click="goToProductCenter3">下载中心</li>
            <li @click="goToProductCenter4">常见问题</li>
            <li @click="goToProductCenter5">技术热线</li>
          </ul>

          <ul>
            <li> <router-link to="/joinGo" class="custom-router">联系我们</router-link></li>
            <li> <router-link to="/justGo" class="custom-rout">加入我们</router-link></li>
            <li> <router-link to="/joinGo" class="custom-rout">联系我们</router-link></li>
          </ul>

        </div>
      </div>
      <div style="position: relative;">
        <img src="../assets/1660.webp" style="width:100%;height:.3125rem;border:none; display:block;">
        <div style="position: absolute; bottom: .0156rem;right: 1.4583rem;">
          <div class="social-share" data-initialized="true">
            <span style="margin-right: .0521rem;font-size: .0938rem;font-weight: bold; color: #ADADAD;">分享到:</span>
            <a href="http://www.weibo.com" class="social-share-icon icon-weibo"
              style="display:inline-block;width:.1563rem;height: .1563rem;overflow: hidden;"></a>
            <a href="#" class="social-share-icon icon-qq"
              style="width: .1563rem;height: .1563rem;overflow: hidden;"></a>
            <a href="#" class="social-share-icon icon-qzone"
              style="width: .1563rem;height: .1563rem;overflow: hidden;"></a>
            <a href="#" class="social-share-icon icon-wechat"
              style="width: .1563rem;height: .1563rem;overflow: hidden;"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        showFh:false,
        showCenter: false, // 控制下拉菜单的显示状态
        showJoin: false,
        showSup : false,
        showServe :false,
       showMor :false,
       showPro :false,
        isOpen: false,
        isDesktop: window.innerWidth > 768,
        isNavOpen: false, // 控制导航栏开关状态
        navItems: ['Home', 'About', 'Services', 'Contact'],// 导航栏菜单项
        activeIndex: 0,
        boxColor: '#5B5B5B',
        logoSrc: require('../assets/丰海彩色logo.png'), // 
        boxBackgroundColor: '#fff',
        centerColor: '#5b5b5b',
        showBackTop: false,
        textColor: '', // 初始化字体颜色为空
        isModalVisible: false,
        value1: 'first',// 当前选中的导航项索引

      };
    },
    mounted() {
      this.handleResize(); // 初始化时调用一次
      window.addEventListener("resize", this.handleResize);
      // 监听窗口大小变化
      this.$router.afterEach((to) => {
        if (to.path === '/') {
          this.activeIndex = 0;
        } else if (to.path === '/PageHome') {
          this.activeIndex = 0;
        }
        else if (to.path === '/product-center') {
          this.activeIndex = 1;
        }
        // 其他导航项的判断
        else if (to.path === '/soluTion') {
          this.activeIndex = 2;
        }
        else if (to.path === '/serviceSupport') {
          this.activeIndex = 3;
        }
        else if (to.path === '/aboutUs') {
          this.activeIndex = 4;
        }
        else if (to.path === '/joinUs') {
          this.activeIndex = 5;
        }
        else if (to.path === '/justGo') {
          this.activeIndex = 5;
        }
        else if (to.path === '/joinGo') {
          this.activeIndex = 5;
        }
        else if (to.path === '/newsCentres') {
          this.activeIndex = 6;
        }
        else if (to.path === '/casePresentation') {
          this.activeIndex = 7;
        }
      });
      window.addEventListener('scroll', this.changeBackgroundColor);
      let timer = null;
      window.addEventListener('scroll', () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.showBackTop = false;
        }, 3000);
        if (window.pageYOffset > 200) {
          this.showBackTop = true;
        } else {
          this.showBackTop = false;
        }
      });
    },
    created() {
      window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
      toggleMenu() {
        this.isOpen = !this.isOpen;
        this.toggleBodyScroll(this.isOpen);
      },
      closeMenu() {
        this.isOpen = false;
        this.toggleBodyScroll(false);
      },
      toggleBodyScroll(disable) {
        if (disable) {
          document.body.style.overflow = 'hidden'; // 禁止滚动
        } else {
          document.body.style.overflow = ''; // 恢复滚动
        }
      },
      navigateTo(route) {
        this.closeMenu();
        this.$router.push(route);
      },
      handleResize() {
        this.isDesktop = window.innerWidth > 768; // 根据窗口宽度更新状态
      },

      handleScroll() {
        const scrollTop1 = window.pageYOffset || document.documentElement.scrollTop;
        const liElements = document.querySelectorAll('.centered-li');
        if (scrollTop1 > 0) {
          liElements.forEach((li) => {
            li.classList.replace('default', 'dafalt');
          });
        } else {
          liElements.forEach((li) => {
            li.classList.replace('dafalt', 'default');
          });
        }
      },
      changeBackgroundColor() {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > 0) {
          this.boxBackgroundColor = '#407BF5';
          this.boxColor = '#eee';
          this.centerColor = '#eee';
          this.logoSrc = require('../assets/丰海白色logo.png');
        } else {
          this.boxBackgroundColor = '#fff';
          this.boxColor = '#5b5b5b';
          this.centerColor = '#5b5b5b';
          this.logoSrc = require('../assets/丰海彩色logo.png'); //这是默认的logo
        }
      },
      contact() {
        this.$router.push({ path: '/joinUs', query: { tab: 'four' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 
        window.scrollTo(0, scrollTo);
      },
      contactPro() {
        this.$router.push({ path: '/product-center' });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 900;
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 
        window.scrollTo(0, scrollTo);
        this.isModalVisible = false;
        document.body.style.overflow = "auto";
      },
      goToProductCenter() {
        this.$router.push('/product-center');
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 900;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); // 
      },
      goToProductCenter2() {
        this.$router.push('/soluTion');
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 900;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); // 
      },
      goToProductCenter3() {
        this.$router.push({ path: '/serviceSupport', query: { tab: 'first' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 900;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },
      goToProductCenter4() {
        this.$router.push({ path: '/serviceSupport', query: { tab: 'second' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 900;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },
      goToProductCenter5() {
        this.$router.push({ path: '/serviceSupport', query: { tab: 'third' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 900;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },
      goToProductCenter6() {
        this.$router.push({ path: '/aboutUs', query: { tab: 'first' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo);
      },
      goToNew6() {
        this.$router.push({ path: '/newsCentres', query: { tab: 'first' } });
        const windowHeight = window.innerHeight;
        const targetOffsetTop = 1000;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo);
      },
      goToNew7() {
        this.$router.push({ path: '/newsCentres', query: { tab: 'second' } });
        const windowHeight = window.innerHeight;
        const targetOffsetTop = 1000;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo);
      },
      goToNew8() {
        this.$router.push({ path: '/aboutUs', query: { tab: 'first' } });

        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo);
      },
      goToNew9() {
        this.$router.push({ path: '/aboutUs', query: { tab: 'second' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },

      goToNew10() {
        this.$router.push({ path: '/aboutUs', query: { tab: 'third' } });

        const windowHeight = window.innerHeight;
        const targetOffsetTop = 1000;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo);
      },
      goToNew11() {
        this.$router.push({ path: '/aboutUs', query: { tab: 'Five' } });
        const windowHeight = window.innerHeight;
        const targetOffsetTop = 1000;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo);
      },
      goToNew12() {
        this.$router.push({ path: '/aboutUs', query: { tab: 'six' } });
        const windowHeight = window.innerHeight;
        const targetOffsetTop = 1000;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo);
      },
      goToPct1() {
        this.$router.push({ path: '/product-center', query: { tab: 'first' } });
        const windowHeight = window.innerHeight;
        const targetOffsetTop = 1000;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo);
      },
      goToCase1() {
        this.$router.push({ path: '/casePresentation', query: { tab: 'first' } });
        const windowHeight = window.innerHeight;
        const targetOffsetTop = 1000;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo);
      },
      goToCase2() {
        this.$router.push({ path: '/casePresentation', query: { tab: 'six' } });
        const windowHeight = window.innerHeight;
        const targetOffsetTop = 1000;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo);
      },
      goToCase3() {
        this.$router.push({ path: '/casePresentation', query: { tab: 'seven' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },

      goToCase4() {
        this.$router.push({ path: '/casePresentation', query: { tab: 'eight' } });
        const windowHeight = window.innerHeight;
        const targetOffsetTop = 1000;
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo);
      },
      goToCase5() {

        this.$router.push({ path: '/casePresentation', query: { tab: 'third' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //

      },
      goToCase6() {
        this.$router.push({ path: '/casePresentation', query: { tab: 'nine' } });

        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //

      },
      goToCase7() {
        this.$router.push({ path: '/casePresentation', query: { tab: 'four' } });

        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //

      },
      goToCase8() {
        this.$router.push({ path: '/casePresentation', query: { tab: 'Five' } });

        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //

      },

      goToPct2() {
        this.$router.push({ path: '/product-center', query: { tab: 'six' } });

        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //

      },
      goToPct3() {
        this.$router.push({ path: '/product-center', query: { tab: 'seven' } });

        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //

      },
      goToPct4() {
        this.$router.push({ path: '/product-center', query: { tab: 'eight' } });

        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //

      },
      goToPct5() {
        this.$router.push({ path: '/product-center', query: { tab: 'third' } });

        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //

      },
      goToPct6() {
        this.$router.push({ path: '/product-center', query: { tab: 'nine' } });

        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //

      },
      goToPct7() {
        this.$router.push({ path: '/product-center', query: { tab: 'four' } });

        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //

      },
      goToPct8() {
        this.$router.push({ path: '/product-center', query: { tab: 'Five' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },
      goToProductCenter7() {
        this.$router.push({ path: '/aboutUs', query: { tab: 'second' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },
      goToProductCenter77() {
        this.$router.push({ path: '/aboutUs', query: { tab: 'eight' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },
      goToProductCenter8() {
        this.$router.push({ path: '/aboutUs', query: { tab: 'third' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },
      goToProductCenter9() {
        this.$router.push({ path: '/aboutUs', query: { tab: 'four' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },
      goToProductCenter10() {
        this.$router.push({ path: '/aboutUs', query: { tab: 'Five' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },
      goToProductCenter11() {
        this.$router.push({ path: '/aboutUs', query: { tab: 'six' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },
      goToProductCenter13() {
        this.$router.push({ path: '/joinUs', query: { tab: 'first' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },
      goToProductCenter14() {
        this.$router.push({ path: '/joinUs', query: { tab: 'second' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },
      goToProductCenter15() {
        this.$router.push({ path: '/joinUs', query: { tab: 'third' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },
      goToProductCenter16() {
        this.$router.push({ path: '/joinUs', query: { tab: 'four' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },
      goToProductCenter66() {
        this.$router.push({ path: '/soluTion/samCov18' });

      },
      goToProductCenter67() {
        this.$router.push({ path: '/samCov18' });

      },
      goToProductCenter68() {
        this.$router.push({ path: '/samCov18' });

      },
      goToProductCenter69() {
        this.$router.push({ path: '/samCov18' });

      },
      changeTextColor() {
        this.textColor = 'red';
      },
      changeColor(index) {
        this.activeIndex = index;
      },
      showModal() {
        this.isModalVisible = true;
        document.body.style.overflow = "hidden";
      },
      hideModal() {
        this.isModalVisible = false;
        document.body.style.overflow = "auto";
      }
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.changeBackgroundColor);
      window.removeEventListener('scroll', this.handleScroll);
    }
  };
</script>
<style scoped>
  .opl {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* 设置为竖直方向排列 */
    justify-content: flex-start;
    /* 子项从顶部开始对齐 */
    align-items: flex-start;
    /* 子项与左侧对齐 */
    /* gap: .1563rem; */

    padding: 0;
    margin: 0;
    list-style: none;
    /* 去掉默认的列表样式 */
    box-sizing: border-box;
  }

  .opl li {
    width: 100%;
    height: .2604rem;
  }

  el-divider {
    width: 100%;
    /* 分割线宽度设置为占满父容器 */
  }

  /* 菜单栏基本样式 */
  /* 菜单栏基本样式 */
  .menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 80vh;
    background-color: white;
    overflow: hidden;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    /* 菜单栏比遮罩层优先显示 */
    padding: .3125rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  /* 菜单项样式 */
  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }



  .el-icon-close {
    background: none;
    border: none;
    font-size: .5729rem;
    /* font-weight: bold; */
    /* color: #333; */
    cursor: pointer;
    align-self: flex-end;

  }


  .page9 ul {
    list-style: none;
    color: #ccc;
    font-size: .0781rem;
    line-height: .1354rem;
    top: -.0521rem;
    transform: translate(0px, -.2604rem);
  }

  .page9 ul li:hover {
    color: #FFF;
    font-size: .0833rem;
  }

  .page9 ul li:first-child {
    font-family: 'HarmonyOS_Sans_SC_Regular';

    font-size: .0938rem;
    font-weight: 800;
    color: #fff;
    line-height: .1563rem;
    margin-bottom: .0781rem;

  }

  .page6 {

    height: 2.8594rem;
    position: relative;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
  }

  .close {
    width: .2083rem;
    height: .2083rem;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .125rem;
    position: absolute;
    top: .0521rem;
    right: .0521rem;
    cursor: pointer;
  }

  .close:before {
    content: "×";
  }

  .close:hover {
    background-color: #eee;
  }

  .search-icon1 {
    background-image: url('../assets/icons8-search-48.png');
    background-size: cover;
    width: .1563rem;
    height: .1563rem;
    position: absolute;
    left: .0365rem;
    bottom: .0469rem;

  }

  .modal-text {
    margin-top: .7813rem;
    text-align: center;
    color: white;
    padding: .1042rem;
  }

  .modal-text h2 {

    font-size: .125rem;
    font-weight: 700;
    margin-bottom: .1042rem;
  }

  .hover1-div:hover {
    font-size: .1198rem !important;
  }

  .search-box {
    display: flex;
    justify-content: center;
    margin-bottom: .1042rem;
  }

  .search-box input {
    width: 1.0417rem;
    height: .2083rem;
    padding: .0521rem;
    font-size: .0833rem;
  }

  .search-history {
    text-align: center;
    font-size: .0833rem;
  }

  .modal-open {
    overflow: hidden;
  }

  .default {
    background: linear-gradient(to bottom, #407BF5 0%, #407BF5 81%, #FFF 81%, #FFF 88%, #407BF5 88%, #407BF5 91%, #FFF 91%, #FFF 100%);
    transform: translateY(-.0313rem);
    color: #FFF !important;
  }

  .dafalt {
    background: linear-gradient(to bottom, #FFF 0%, #FFF 81%, #407BF5 81%, #407BF5 88%, #FFF 88%, #FFF 91%, #407BF5 91%, #407BF5 100%);
    transform: translateY(-.0313rem);
    color: #407BF5 !important;


  }



  .oDiv ul li {
    display: list-item;
    text-indent: -1em;
    padding: .0208rem;
    font-size: .0729rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #5B5B5B;
    line-height: .125rem;
  }

  .oDiv ul {
    left: .0521rem;
    list-style-type: circle;
  }

  .size2 {
    width: .6667rem;
    height: .2344rem;
    font-size: .1667rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    color: #5B5B5B;
  }

  .size {
    width: .0625rem;
    height: .0938rem;
  }

  .size {
    width: 1.5521rem;
    height: .1875rem;
    font-size: .1667rem;
    font-family: Arial, Arial;
    font-weight: 400;
    color: #1C4DF0;
    line-height: .4167rem;
  }

  .box {

    width: 10rem;
    height: .4167rem;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  .custom-router-link {
    text-decoration: none;
    color: #333;
  }

  .centered-ul {
    display: flex;
    justify-content: center;
    align-items: center;
    height: .4167rem;
    background-color: white;
    list-style: none;
    padding: 0;
    margin-left: .4167rem;
    margin-right: .4167rem;
  }

  .centered-li {
    width: .5208rem;
    text-align: center;
    height: .4167rem;
    line-height: .4167rem;
    font-size: .0938rem;
    color: #000;
  }

  /* .el-scrollbar__thumb {
  background-color: #c1c1c1;
  border-radius: 4px;
} */


  .logo {
    /* position: absolute; */
    margin-left: .625rem;
    display: block;
    height: .2083rem;
    /* width: .7708rem; */
    /* vertical-align: middle; */
  }

  .kk {
    /* position: absolute; */
    margin-right: .8854rem;
    right: .4219rem;
    height: .4167rem;
    display: flex;
    align-items: center;

  }


  @font-face {
    font-family: 'PingFang SC, PingFang SC';
    src: url('../assets/font/PingFang Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'YouSheBiaoTiHei';
    src: url('../assets/font/YouSheBiaoTiHei.ttf') format('truetype');
  }

  /* */

  span {
    height: .2083rem;
    font-size: .0729rem;
    margin-right: .0313rem;
  }

  .carousel {
    width: 100%;
    height: 3.3333rem;
    background-color: white;
  }

  .carousel2 {
    width: 100%;
    height: 3.5313rem;
    background-color: #EDEDED;
  }

  .centered-li {
    position: relative;
    /* 使下拉菜单相对于父元素定位 */
  }

  .dropdown-menu {
    max-height: 0;
    
    overflow: hidden;
 
    transition: max-height 0.5s ease;
  
    background-color: white;
    
    z-index: 1000;
  }

  .dropdown-menu li {
    height: .3125rem;
  }


  .dropdown-menu.show {
    max-height: 3.125rem;
  }
  .dropdown-enter-active,
  .dropdown-leave-active {
    transition: all 0.3s ease;
  }

  .dropdown-enter-from,
  .dropdown-leave-to {
    max-height: 0;
    opacity: 0;
    opacity: 0;
  }



  .dropdown-menu li:hover {
    color: #407BF5;
  }

  .custom-router-link:hover {
    color: #407BF5;
  }

  .centered-li:hover .dropdown-menu {
    display: block;
    background-color: #fff;
    color: #333;
    list-style: none;
  }

  .custom-router {
    text-decoration: none;
    color: #FFF;
  }

  .custom-rout {
    text-decoration: none;
    color: #ccc;
  }

  .custom-rout5 {
    text-decoration: none;
    color: #ccc;

  }

  .custom-rout5:hover {
    font-size: .0833rem;
    color: #fff;
  }

  .custom-rout:hover {
    font-size: .0833rem;
    color: #fff;
  }
</style>
