<template>

    <div>
  

  
    
      
  
      <div style="height:auto;display: flex; justify-content: center;align-items: center;margin-top: .1563rem;margin-bottom: .1563rem;">
  
        <div class="tab-container">
          <el-tabs stretch="true" v-model="activeName" @tab-click="handleClick"
            style="margin-top: .2604rem;height: auto;font-size: .1042rem;">
  
  
  
            <el-tab-pane label="人才理念" name="first">
  
              <div
                style="width: 100%;border-bottom: #eeeeee solid .0052rem;color: #2056c0;font-weight: bold;    font-size: .1042rem; margin-top: .5208rem;">
                人才理念</div>
              <div>
                <p style="height: .5208rem;line-height: .5208rem;color: #999;font-size: .0833rem;">
                  以人为本，人才第一，利人利企，和谐共赢。
                </p>
                <p style="height: .5208rem;line-height: .5208rem;color: #999;font-size: .0833rem;">
                  人尽其才，才尽其用，用人所长，补其所短。
                </p>
                <p style="height: .5208rem;line-height: .5208rem;color: #999;font-size: .0833rem;">
                  关爱员工，真诚沟通，海纳百川，尊重个性。
                </p>
                <div
                  style="width: 100%;border-bottom: #eeeeee solid .0052rem;color: #2056c0;font-weight: bold;    font-size: .1042rem; margin-top: .5208rem;">
                  激励回报</div>
  
  
                <div style="border: .0052rem solid #eee ;position: relative;height: .7188rem;margin-top: .2604rem;">
                  <div
                    style="width: .4427rem;background-color: #8faadf;display: flex;position: absolute;height: 100%; align-items: center; justify-content: center;   writing-mode: vertical-rl;">
                    薪酬理念</div>
                  <div
                    style="font-size:.0729rem; line-height: .125rem;color: #999;padding: .2083rem;margin-left: .1042rem;position: absolute;left: .4427rem;">
                    中高薪酬，全面激励——公司一般岗位实行高于行业水平的中上薪酬，关键岗位实行富有竞争力的高薪酬，
                    同时在回报形式上采用长期回报与短期回报、货币回报与非货币回报的多样化组合，
                    全面吸引高素质人才投身于公司事业中。</div>
                </div>
  
                <div style="border: .0052rem solid #eee ;position: relative;height: 1.2604rem;margin-top: .2604rem;">
                  <div
                    style="width: .4427rem;background-color: #dd9ca1;display: flex;position: absolute;height: 100%; align-items: center; justify-content: center;   writing-mode: vertical-rl;">
                    福利待遇</div>
                  <div
                    style="font-size:.0729rem; line-height: .125rem;color: #999;padding: .2083rem;margin-left: .1042rem;position: absolute;left: .4427rem;">
                    <p>1、完善的培训与职业发展规划，轻松愉快、相互尊重的企业文化氛围；</p>
                    <p>2、福利：公司年度旅游、部门活动、户外拓展、年度体检，年会等；</p>
                    <p>3、薪酬：提供具有市场竞争力的薪酬，包括基本工资+工龄工资+补贴+年终奖；</p>
                    <p>4、保险：六险（社会保险）、住房公积金、商业意外险；</p>
                    <p>5、补贴：通讯补贴、生日慰问金、节日津贴、生育津贴、高温补贴等；</p>
                    <p>6、工作日免费提供午餐及晚餐；</p>
                    <p>7、工时：五天工作制（周一至周五：8:30-17:30，午休：12:00-13:30；</p>
                    <p>8、假期：国家法定假期及超长带薪年休假。</p>
                  </div>
                </div>
  
  
  
  
  
  
                <div
                  style="width: 100%;border-bottom: #eeeeee solid .0052rem;color: #2056c0;font-weight: bold;    font-size: .1042rem; margin-top: .5208rem;">
                  丰海生活</div>
                <div style="font-size:.0729rem; line-height: .125rem;color: #999;margin-top: .0781rem;">
                  <p><span style="font-size: .0729rem;color: #bb3944;font-weight: bold;">环境:</span>优美舒适的工作环境，和谐尊重的文化氛围。</p>
                  <img src="" alt="">
                </div>
  
                <div style="font-size:.0729rem; line-height: .125rem;color: #999;">
                  <p><span
                      style="font-size: .0729rem;color: #bb3944;font-weight: bold;">员工生活:</span>我们注重员工的身心发展，通过举办年度旅游、部门活动、户外拓展、羽毛球比赛等活动，劳逸结合，丰富员工的生活。
                  </p>
                  <img src="" alt="">
                </div>
  
  
  
              </div>
  
  
            </el-tab-pane>
  
            <el-tab-pane label="校园招聘" name="second">
  
              <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item title="销售工程师" name="1">
                  <div>
                    <div style="display: flex; justify-content: space-between;">
                      <ul style="display: flex; flex-direction: row; list-style: none;">
                        <li style="width: 1.25rem;">招聘人数: 若干</li>
                        <li style="width: 1.25rem;">性别要求: 无</li>
                        <li style="width: 1.25rem;">学历要求: 不限</li>
                        <li style="width:1.25rem;">工作地点: 广州</li>
                        <li style="width: 1.25rem;">薪资待遇: 面议</li>
                      </ul>
                    </div>
                    <div style="margin-top: .2604rem;color: #407BF5;font-weight: bold;"> 【岗位职责】</div>
                    <ul>
                      <li>1、负责公司产品的销售</li>
                      <li>2、根据公司销售计划，完成产品销售任务，达成销售目标</li>
                      <li>3、了解产品知识，为客户提供产品解答，提供售后服务，维护现有客户，开发新客户，建立并维护长期合作关系</li>
                      <li>4、参与销售项目的策划和实施，确保项目进度及合同执行</li>
                    </ul>

                    <div style="margin-top: .2604rem;color: #bb3944;font-weight: bold;">【任职要求】</div>
                    <div style="line-height: .125rem;">
                      <ul style="list-style: none;">
                        <li>1、电子信息、通信工程、电气、自动化等相关专业</li>
                        <li>2、不限工作经验，22-25岁，具备良好的职业操守，具备较强的责任心</li>
                        <li>3、良好的沟通和表达能力，具备良好的人际交往能力:-敏锐的市场洞察力和抗压能力</li>
                        <li>4、对销售工作充满热情，具备积极向上的心态和强烈的责任感和使命感</li>
                        <li>5、工作态度认真负责，主动性高，具有良好的沟通能力及较强的团队合作意识，责任心强，能够适应经常性出差</li>
                       
                      </ul>
                    </div>
                    <div>以上职位一经录用，待遇从优，欢迎有意向的人才投递简历。</div>
  
                    <el-button style="margin-top: .0781rem;" @click="gotoJoin">联系我们</el-button>
  
  
  
                  </div>
  
                </el-collapse-item>
                <el-collapse-item title="硬件开发工程师" name="2">
                  <div>
                    <div style="display: flex; justify-content: space-between;">
                      <ul style="display: flex; flex-direction: row; list-style: none;">
                        <li style="width: 1.25rem;">招聘人数: 若干</li>
                        <li style="width: 1.25rem;">性别要求: 无</li>
                        <li style="width: 1.25rem;">学历要求: 不限</li>
                        <li style="width:1.25rem;">工作地点: 广州</li>
                        <li style="width: 1.25rem;">薪资待遇: 面议</li>
                      </ul>
                    </div>
                    <div style="margin-top: .2604rem;color: #407BF5;font-weight: bold;"> 【岗位职责】</div>
                    <ul>
                      <li>1、负责公司硬件产品的研发、测试和维护负责现有产品硬件设计的优化和改进</li>
                      <li>2、协助项目经理完成项目计划，并参与项目的需求讨论和分工</li>
                      <li>3、参与产品的结构设计、功能需求讨论和测试</li>
                      <li>4、熟悉各种硬件设计工具和技术，如Altium Designer、Cadence等</li>
                  
                    </ul>
                    <div style="margin-top: .2604rem;color: #bb3944;font-weight: bold;">【任职要求】</div>
                    <div style="line-height: .125rem;">
                      <ul style="list-style: none;">
                        <li>1、本科及以上学历，电子、自动化等相关专业</li>
                        <li>2、良好的沟通能力和团队协作精神，具备项目实施经验优先</li>
                        <li>3、熟悉数字电路设计、PCB设计，熟练使用AltiumDesigner、Cadence等设计软件</li>
                        <li>4、具有较强的硬件设计能力</li>
                        <li>5、有责任心，对工作认真负责，具备良好的抗压能力</li>
                        
                        <li></li>
                      </ul>
                    </div>
                    <div>以上职位一经录用，待遇从优，欢迎有意向的人才投递简历。</div>
  
                    <el-button style="margin-top: .0781rem;" @click="gotoJoin">联系我们</el-button>
  
  
  
                  </div>
  
                </el-collapse-item>
  
              </el-collapse>
  
  
            </el-tab-pane>
  
            <el-tab-pane label="社会招聘" name="third">

          





             
  
              <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item title=" 毫米波雷达算法与软件工程师" name="1">
                  <div>
                    <div style="display: flex; justify-content: space-between;">
                      <ul style="display: flex; flex-direction: row; list-style: none;">
                        <li style="width: 1.25rem;">招聘人数: 若干</li>
                        <li style="width: 1.25rem;">性别要求: 无</li>
                        <li style="width: 1.25rem;">学历要求: 不限</li>
                        <li style="width:1.25rem;">工作地点: 广州</li>
                        <li style="width: 1.25rem;">薪资待遇:<span style="color: #f6b947;font-weight: bold;"> 1.8-3万</span></li>
                      </ul>
                    </div>
                    <div style="margin-top: .2604rem;color: #407BF5;font-weight: bold;"> 【岗位职责】</div>
                    <ul>
                      <li>  1、毫米波雷达算法实现和相关算法测试优化工作，分析并解决项目开发中出现的信号处理相关问题</li>
                      <li>  2、撰写软件开发的相关文档</li>
                     
                      <li>  3、积极参与毫米波雷达试验和测试</li>
                    </ul>
                    <div style="margin-top: .2604rem;color: #bb3944;font-weight: bold;">【任职要求】</div>
                    <div style="line-height: .125rem;">
                      <ul style="list-style: none;">
                        <li>1、电子工程、计算机专业、应用数学或应用物理方向硕士及以上学位:</li>
                        <li>2、熟悉雷达信号处理和数据处理流程，具有较好的阵列信号处理基础</li>
                        <li>3、熟悉软件开发流程，熟练使用Matlab、C/C++等编程语言</li>
                        <li>4、有TI/NXP/加特兰芯片应用开发经验者优先</li>
                       
                        <li></li>
                      </ul>
                    </div>
                    <div>以上职位一经录用，待遇从优，欢迎有意向的人才投递简历。</div>
  
                    <el-button style="margin-top: .0781rem;" @click="gotoJoin">联系我们</el-button>
  
  
                  </div>

                  主要职责





任职资格:




  
                </el-collapse-item>
                <el-collapse-item title="硬件工程师" name="2">
                  <div>
                    <div style="display: flex; justify-content: space-between;">
                      <ul style="display: flex; flex-direction: row; list-style: none;">
                        <li style="width: 1.25rem;">招聘人数: 若干</li>
                        <li style="width: 1.25rem;">性别要求: 无</li>
                        <li style="width: 1.25rem;">学历要求: 不限</li>
                        <li style="width:1.25rem;">工作地点: 广州</li>
                        <li style="width: 1.25rem;">薪资待遇: 面议</li>
                      </ul>
                    </div>
                    <div style="margin-top: .2604rem;color: #407BF5;font-weight: bold;"> 【岗位职责】</div>
                    <ul>
                      <li>1.负责单板硬件方案、详细设计、原理图设计、PCB设计</li>
                      <li>2.负责物料跟踪、BOM整理及生产试制工作</li>
                      <li>3.负责PCB板级信号测试、调试、功能验证、故障分析与整改工作</li>
                      <li>4.输出硬件电路接口文档，支持底层软件工程师的开发工作</li>
                      <li>5.端到端的跟踪解决可靠性试验、小批量、量产、生命周期内出现的问题</li>
              
                    </ul>
                    <div style="margin-top: .2604rem;color: #bb3944;font-weight: bold;">【任职要求】</div>
                    <div style="line-height: .125rem;">
                      <ul style="list-style: none;">
                        <li>1.电子、自动化、电气、通信相关专业本科以上学历</li>
                        <li>2.能独立完成数字电路和数模混合电路设计，对信号完整性/电源完整性有一定了解</li>
                        <li>3.具备硬件工程师专业知识，例如数字电路、模拟电路，有较好的问题分析能力</li>
                        <li>4.精通ORCAD，Allegro，protel等软件</li>
                        <li>5.精通使用万用表，示波器,频谱仪等仪器</li>
                        <li>6.工作热情、积极主动、有责任心，具有团队合作精神7.有LED显示驱动设计及毫米波雷达设计经验优先</li>
                        <li></li>
                      </ul>
                    </div>
                    <div>以上职位一经录用，待遇从优，欢迎有意向的人才投递简历。</div>
                    <el-button style="margin-top: .0781rem;" @click="gotoJoin">联系我们</el-button>
                  </div>
  
                </el-collapse-item>
  
              </el-collapse>
            </el-tab-pane>
  
      
  
          </el-tabs>
        </div>
  
  
      </div>
    </div>
  </template>
  
  <script>
  
  
  
  
    export default {
  
      data() {
        return {
          activeName: 'first',
          tableData: [{
            date: '2024-01-15',
            name: '软件工程师',
  
          }, {
            date: '2024-01-15',
  
            name: '硬件工程师'
          },
          {
            date: '2024-01-15',
  
            name: '销售工程师'
          }],
          tableData1: [{
            date: '2024-01-15',
            name: '应届毕业生',
  
          }, {
            date: '2024-01-15',
  
            name: '售后工程师'
          },
          {
            date: '2024-01-15',
  
            name: '嵌入式软件工程师'
          },
          {
            date: '2024-01-15',
  
            name: '硬件工程师'
          },
          {
            date: '2024-01-15',
  
            name: '高级软件工程师'
          }],
          pageTitle: "加入我们",
          positions: [
            {
              id: 1,
              title: "前端工程师",
              description: "负责开发和维护公司网站前端部分"
            },
            {
              id: 2,
              title: "后端工程师",
              description: "负责开发和维护公司网站后端部分"
            },
            {
              id: 3,
              title: "UI/UX设计师",
              description: "负责设计用户界面和用户体验"
            }
          ]
        };
      },
     
  watch: {
    '$route.query.tab': {
      immediate: true,
      handler(newTab) {
        this.handleTabChange(newTab);
      }
    }
  },
      mounted() {
        this.$nextTick(() => {
      window.scrollTo(0, 400); // 将页面滚动到5.2083rem的位置
    });
        // 在组件挂载完成后，将活动标签设置为第一个标签的name属性值
     
  
  
  
      },
      methods: {
        gotoJoin(){
          this.$router.push({ path: '/joinGo'});
        }
      
  
  
  
  
      }
    };
  </script>
  <style scoped>
    .about-us {
      max-width: 6.25rem;
      margin: 0 auto;
      padding: .2083rem;
    }
  
    .banner {
      text-align: center;
      margin-bottom: .2083rem;
    }
  
    .banner-title {
      font-size: .1875rem;
      font-weight: bold;
      color: #333;
    }
  
    .banner-image {
      width: 100%;
      max-height: 2.0833rem;
      object-fit: cover;
      border-radius: .0417rem;
    }
  
    .content {
      margin-bottom: .2083rem;
    }
  
    .section {
      margin-bottom: .1042rem;
    }
  
    .section-title {
      font-size: .125rem;
      font-weight: bold;
      color: #333;
    }
  
    .section-text {
      font-size: .0833rem;
      color: #555;
    }
  
    .team-title {
      font-size: .125rem;
      font-weight: bold;
      color: #333;
      margin-bottom: .1042rem;
    }
  
    .team-members {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .team-member {
      display: flex;
      align-items: center;
      margin: .0521rem;
    }
  
    .item-container {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          position: relative;
      }
  
      .opp /deep/ .el-tabs__nav-scroll {
          background-color: #E9E9E9;
  
      }
  
      .frp {
          background-color: #E9E9E9;
          width: .1042rem;
          height: .1042rem;
      }
  
      .title-container {
          flex-basis: 100%;
      }
  
      .item-title {
          /* 标题样式 */
      }
  
      .opp /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
          border-right-color: #407BF5;
      }
  
      .opp /deep/ .el-tabs--border-card {
          border: none;
          box-shadow: none;
      }
  
      .item-image {
          margin-left: auto;
      }
  
      .item-container::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 50%;
          height: 100%;
  
      }
  
    .member-avatar {
      width: .4167rem;
      height: .4167rem;
      object-fit: cover;
      border-radius: 50%;
    }
  
    .member-details {
      margin-left: .0521rem;
    }
  
    .member-name {
      font-size: .0833rem;
      font-weight: bold;
      color: #333;
    }
  
    .member-role {
      font-size: .0729rem;
      color: #555;
    }
  
    .year {
  
  
      height: .2083rem;
      line-height: .2083rem;
      font-size: .0833rem;
      border-bottom: #eee solid .0052rem;
      width: .8854rem;
      padding-left: .125rem;
    }
  
    .year b {
      font-size: .1563rem;
      color: #bb3944;
      font-weight: bold;
    }
  
    .el-tabs__item {
      width: 1.5625rem;
      /* 设置每个el-table-pane的宽度 */
    }
  
    .el-tabs.el-tabs--top {
      width: 6.25rem;
    }
  
    #tab-first .el-tabs__item.is-top {
      width: 1.5625rem;
    }
  </style>