<template>
    <div>
        <div style="width: 6.5104rem;margin: 0 auto;">

            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/product-center', query: { tab: 'Five' }}"> LED照明系列 </el-breadcrumb-item>
                <el-breadcrumb-item>LED路灯-0708</el-breadcrumb-item>
            </el-breadcrumb>


        </div>

        <div style="display: flex;justify-content: center;margin-top: .4167rem;">
            <div>
                <img src="../../assets/5800.webp" alt="" style="height: 1.1198rem;">
            </div>
            <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
                <p class="name">LED路灯-0708</p>

                <P class="content" style="text-indent: .1198rem;font-size: .0833rem">
                    LED路灯-0708是一种高效、节能的道路照明设备，采用LED技术作为光源，通过LED路灯实现道路照明，为城市提供更安全、舒适的夜间行车环境。该产品具有优异的亮度和光分布特性，能够提供均匀、明亮的照明效果，有效改善道路的能见度和安全性。同时，其具有较长的使用寿命和低功耗的特点，相比传统的路灯，能够显著降低能源消耗和维护成本。
                </P>
                <div style="margin-top: .1563rem;">

                    <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
                        <icon class="el-icon-phone-outline"></icon>技术热线
                    </div>

                    <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
                    
                </div>
            </div>
        </div>

        
        <div class="title"
            style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ; margin: 0 auto; margin-top: .1042rem; ">
            详细参数
        </div>

        <div class="msgList" style="width: 6.5104rem;margin: 0 auto;">
            <ul>
                <li>
                    <div class="txt"><em>输入电压</em></div>
                    <div class="msg">AC85-305V</div>
                </li>
                <li>
                    <div class="txt"><em>频率范围</em></div>
                    <div class="msg">50-60Hz</div>
                </li>
                <li>
                    <div class="txt"><em>相关色温</em></div>
                    <div class="msg">暖白光2850k-3200k 中性白3700k-4200k 冷白光4700k-5300k</div>
                </li>
                <li>
                    <div class="txt"><em>抗风指数</em></div>
                    <div class="msg">12级大风</div>
                </li>
                <li>
                    <div class="txt"><em>照度标准</em></div>
                    <div class="msg">城市道路照明设计标准(CJJ45-2015)</div>
                </li>

            </ul>
        </div>

        <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
            <el-button @click="goBack">返回列表</el-button>
        </div>


    </div>




</template>
<script>


    export default {
        methods: {
            goBack() {

                this.$router.push({ path: '/product-center', query: { tab: 'Five' } });
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            }
            ,
            //       goTo(){
            //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
            //     const windowHeight = window.innerHeight; // 
            //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
            //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
            //     window.scrollTo(0, scrollTo); //
            //   },
        }
    }




</script>

<style scoped>
    .name {
        padding-bottom: .1042rem;
        border-bottom: #eeeeee solid .0052rem;
        font-size: .1042rem;
        color: #2056c0;
        font-weight: bold;
        position: relative;
    }

    .msgList {
        padding-top: .2604rem;

        border-bottom: #eee solid .0052rem;
    }

    .msgList li {
        border: #c6c6c6 solid .0052rem;
        padding: .0521rem .1042rem .0521rem 1.0938rem;
        position: relative;
        font-size: .0729rem;
        margin-bottom: .0521rem;
        text-align: center;
        line-height: .2604rem;
    }

    li {
        list-style-position: outside;
        list-style-type: none;

    }

    .msgList li .msg {
        line-height: .1667rem;
        color: #999;
    }

    .content {
        padding-top: .1563rem;
        font-size: .0729rem;
        line-height: .125rem;
        color: #434343;

    }

    .tel {
        padding-top: .0521rem;
        font-size: .1875rem;
        color: #bb3944;
        font-family: Impact;
    }

    .txt {
        position: absolute;
        left: 0rem;
        top: 0rem;
        width: .9896rem;
        height: 100%;
        text-align: center;
        color: #FFFFFF;
        background: #2056c0;
        font-size: .0729rem;
        font-family: Arial, "微软雅黑";
    }

    .tittle {
        margin-top: .5208rem;
        height: .2083rem;
        text-align: center;
        line-height: .2083rem;
        font-weight: bold;
        font-size: .0833rem;
        color: #fff;
        position: relative;
    }
</style>