<template>
    <div>
        <div style="width: 6.5104rem;margin: 0 auto;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/product-center', query: { tab: 'third' }}">可变情报板系列</el-breadcrumb-item>
                <el-breadcrumb-item>折叠屏</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="display: flex;justify-content: center;margin-top: .4167rem;">
            <div style="display: inline-block;transform: scale(1.05) translateX(-0.5208rem) translateY(0rem);">
                <img src="../../assets/035.webp" alt="" style="height: 1.1198rem;">

            </div>
            <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
                <p class="name">折叠屏</p>
                <P class="content" style="text-indent: .1198rem;font-size: .0833rem">
                    折叠屏是一种创新的显示技术，它采用可折叠的屏幕设计，使得设备可以在需要时展开成大屏幕，而在不使用时可以折叠成更小、更便携的形态。折叠屏通常应用于智能手机、平板电脑和可穿戴设备等领域。它通过柔性显示技术和可折叠的屏幕材料，实现了屏幕的弯曲和展开，为用户提供了更大的显示面积和更多的操作空间。折叠屏的优势在于其灵活性和便携性，用户可以根据需要选择合适的屏幕大小，同时在折叠状态下也更易于携带和存储。折叠屏技术的不断创新和发展，为用户带来了全新的使用体验和更多的应用可能性。
                </P>
                <div style="margin-top: .1563rem;">
                    <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">

                        <icon class="el-icon-phone-outline">
                        </icon>
                        技术热线
                    </div>
                    <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
                </div>
            </div>
        </div>
        <div class="title"
            style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
            详细参数
        </div>

        <div style="display: flex;justify-content: center;margin-top: .1302rem; margin-bottom: .1302rem; ">
            <el-button @click="goBack">返回列表</el-button>
        </div>
        
    </div>
</template>

<script>
    export default {
        methods: {
            goBack() {
                this.$router.push({ path: '/product-center', query: { tab: 'third' } });
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置 
            }
            ,
            //       goTo(){
            //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
            //     const windowHeight = window.innerHeight; // 
            //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
            //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
            //     window.scrollTo(0, scrollTo); //
            //   },
        }
    }
</script>

<style scoped>

    .name {
        padding-bottom: .1042rem;
        border-bottom: #eeeeee solid .0052rem;
        font-size: .1042rem;
        color: #2056c0;
        font-weight: bold;
        position: relative;
    }

    .msgList {
        padding-top: .2604rem;
        border-bottom: #eee solid .0052rem;
    }

    .msgList li {
        border: #c6c6c6 solid .0052rem;
        padding: .0521rem .1042rem .0521rem 1.0938rem;
        position: relative;
        font-size: .0729rem;
        margin-bottom: .0521rem;
        text-align: center;
        line-height: .2604rem;
    }

    li {
        list-style-position: outside;
        list-style-type: none;

    }

    .msgList li .msg {
        line-height: .1667rem;
        color: #999;
    }

    .content {
        padding-top: .1563rem;
        font-size: .0729rem;
        line-height: .125rem;
        color: #434343;

    }

    .tel {
        padding-top: .0521rem;
        font-size: .1875rem;
        color: #bb3944;
        font-family: Impact;
    }

    .txt {
        position: absolute;
        left: 0rem;
        top: 0rem;
        width: .9896rem;
        height: 100%;
        text-align: center;
        color: #FFFFFF;
        background: #2056c0;
        font-size: .0729rem;
        font-family: Arial, "微软雅黑";

    }

    .tittle {

        margin-top: .5208rem;
        height: .2083rem;
        text-align: center;
        line-height: .2083rem;
        font-weight: bold;
        font-size: .0833rem;
        color: #fff;

        position: relative;
    }
</style>