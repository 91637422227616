<template>
    <div>
        <div style="width: 6.5104rem;margin: 0 auto;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/product-center', query: { tab: 'Five' }}">LED照明系列</el-breadcrumb-item>
                <el-breadcrumb-item>低位照明</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="display: flex;justify-content: center;margin-top: .4167rem;">
            <div>
                <img src="../../assets/5804.webp" alt="" style="height: 1.1198rem;">

            </div>
            <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
                <p class="name">低位照明</p>

                <P class="content" style="text-indent: .1198rem;font-size: .0833rem"> 
                    
                    低位照明是一种特殊的照明设计，主要用于建筑物、景观和道路等场所的地面或低位位置进行照明。
                    低位照明通过将光源安装在地面或低墙上，以低角度照射目标区域，创造出柔和、均匀的照明效果。
                    低位照明可以提高夜间的安全性和可视性，为行人和车辆提供明确的导引和警示，同时营造出独特的视觉效果，增强空间的美感和艺术性。
                    低位照明通常采用LED技术，具有节能、高亮度和长寿命的特点，能够有效降低能源消耗和维护成本。
                    
                </P>
                <div style="margin-top: .1563rem;">

                    <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
                        <icon class="el-icon-phone-outline"></icon> 技术热线
                    </div>

                    <div class="tel"> 181&nbsp;&nbsp;2248&nbsp;&nbsp;4161 </div>

                </div>


            </div>

        </div>

        <div class="title" style="border-bottom: .0156rem solid #2056c0; width: 6.5104rem ; margin: 0 auto;margin-top: .1042rem; ">
            详细参数
        </div>

        
        <div style=" display: flex ; justify-content: center; margin-top: .1302rem; margin-bottom : .1302rem;">
            <el-button @click="goBack">
                返回列表
            </el-button>
        </div>

    </div>




</template>
<script>


    export default {
        methods: {
            goBack() {
  this.$router.push({ path: '/product-center', query: { tab: 'Five' }});
  const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
}
        ,

            //       goTo(){
            //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
            //     const windowHeight = window.innerHeight; // 
            //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
            //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
            //     window.scrollTo(0, scrollTo); //
            //   },

        }
    }
</script>


<style scoped>
    .name {
        padding-bottom: .1042rem;
        border-bottom: #eeeeee solid .0052rem;
        font-size: .1042rem;
        color: #2056c0;
        font-weight: bold;
        position: relative;
    }
    .msgList {

        padding-top: .2604rem;
        border-bottom: #eee solid .0052rem;
    }
    .msgList li {
        border: #c6c6c6 solid .0052rem;
        padding: .0521rem .1042rem .0521rem 1.0938rem;
        position: relative;
        font-size: .0729rem;
        margin-bottom: .0521rem;
        text-align: center;
        line-height: .2604rem;
    }

    li {
        list-style-position: outside;
        list-style-type: none;
    }
    .msgList li .msg {
        line-height: .1667rem;
        color: #999;
    }
    .content {
        padding-top: .1563rem;
        font-size: .0729rem;
        line-height: .125rem;
        color: #434343;
    }
    .tel {
        padding-top: .0521rem;
        font-size: .1875rem;
        color: #bb3944;
        font-family: Impact;
    }
    .txt {
        position: absolute;
        left: 0rem;
        top: 0rem;
        width: .9896rem;
        height: 100%;
        text-align: center;
        color: #FFFFFF;
        background: #2056c0;
        font-size: .0729rem;
        font-family: Arial, "微软雅黑";
    }

    .tittle {
        margin-top: .5208rem;
        height: .2083rem;
        text-align: center;
        line-height: .2083rem;
        font-weight: bold;
        font-size: .0833rem;
        color: #fff;
        position: relative;
    }

</style>