<template>
    <div>
        <div style="width: 6.5104rem;margin: 0 auto;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/product-center', query: { tab: 'eight' }}">智能雾灯、劝导装置系列</el-breadcrumb-item>
                <el-breadcrumb-item>智能雾灯</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="display: flex;justify-content: center;margin-top: .4167rem;">
            <div>
                <img src="../../assets/zzwd5.webp" alt="" style="height: 2.3438rem;">
            </div>

            <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
                <p class="name">智能雾灯</p>
                <P class="content" style="text-indent: .1198rem;font-size: .0833rem">

                    雾天公路行车安全诱导系统是专门针对雾天、夜晚及其他恶劣天气条件下道路行车安全保障而研制的新型智能化产品。产品重点针对高速公路及普通公路的雾区预警防撞，具有闭环自动控制能力，系统具备能见度检测、主动诱导、防撞预警等功能。该产品安装在道路两侧，采用太阳能供电及无线双频控制方式，能够根据天气情况自动启动相应的策略模式。
                </P>
                <div style="position: absolute;bottom: .1563rem;">
                    <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
                        <icon class="el-icon-phone-outline"></icon>技术热线
                    </div>
                    <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>

                </div>


            </div>



        </div>

        <div class="title"
            style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
            详细参数</div>
        <!--  style="width: 6.5104rem;margin: 0 auto;"-->


        <div class="msgList" style="width: 6.5104rem;margin: 0 auto;">
            <ul>
                <li>
                    <div class="txt">
                        <em>规格尺寸</em>
                    </div>
                    <div class="msg">230*230*398(高)mm</div>
                </li>
                <li>
                    <div class="txt">
                        <em>发光强度</em>
                    </div>
                    <div class="msg">在500cd/㎡~7000cd/㎡范围内8档亮度调节功能</div>
                </li>
                <li>
                    <div class="txt">
                        <em>显示内容</em>
                    </div>
                    <div class="msg">黄灯常亮，黄灯同步闪烁，红灯防追尾警示</div>
                </li>
                <li>
                    <div class="txt">
                        <em>检测距离</em>
                    </div>
                    <div class="msg">能够准确检测出车辆的通行情况，检测最大距离不小于20m，适应各种天气</div>
                </li>
                <li>
                    <div class="txt">
                        工作环境
                    </div>
                    <div class="msg">温度-40℃~+65℃，湿度10%~95%RH</div>
                </li>

                <li>
                    <div class="txt">
                        电池
                    </div>
                    <div class="msg">锂电池7.4V 10AH</div>
                </li>
                <li>
                    <div class="txt">
                        太阳能电池板
                    </div>
                    <div class="msg">9V 20W 单晶硅太阳能电池 ,寿命≥15年</div>
                </li>
                <li>
                    <div class="txt">
                        防护等级
                    </div>
                    <div class="msg">IP66</div>
                </li>
                <li>
                    <div class="txt">
                        MTBF
                    </div>
                    <div class="msg">≥30000小时</div>
                </li>
                <li>

                    <div class="txt">
                        发光面积
                    </div>
                    <div class="msg">黄色诱导灯和红色警示灯面积相同,发光面积>0.02m</div>
                </li>
                <li>
                    <div class="txt">
                        <em>工作方式</em>
                    </div>
                    <div class="msg">24小时连续工作，受控制器控制</div>
                </li>

         
                <li>
                    <div class="txt">
                        <em>供电方式</em>
                    </div>
                    <div class="msg">太阳能供电/市电</div>
                </li>
                <li>
                    <div class="txt">
                        <em>消耗功率</em>
                    </div>
                    <div class="msg"> ≤3W </div>
                </li>
                <li>
                    <div class="txt">
                        <em>控制方式</em>
                    </div>
                    <div class="msg">无线控制</div>
                </li>
                <li>
                    <div class="txt">
                        <em>外壳材质</em>
                    </div>
                    <div class="msg">主体箱采用工程塑料材质</div>
                </li>
                <!-- <li>
                    <div class="txt">
                        <em>防护等级</em>
                    </div>
                    <div class="msg">IP66</div>
                </li> -->
            </ul>
        </div>



        <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
            <el-button @click="goBack">返回列表</el-button>
        </div>


    </div>




</template>
<script>


    export default {
        methods: {
            goBack() {
                this.$router.push({ path: '/product-center', query: { tab: 'eight' } });
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            }

            ,

            //       goTo(){
            //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
            //     const windowHeight = window.innerHeight; // 
            //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
            //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
            //     window.scrollTo(0, scrollTo); //
            //   },
        }
    }




</script>

<style scoped>
    .name {
        padding-bottom: .1042rem;
        border-bottom: #eeeeee solid .0052rem;
        font-size: .1042rem;
        color: #2056c0;
        font-weight: bold;
        position: relative;
    }

    .msgList {
        padding-top: .2604rem;

        border-bottom: #eee solid .0052rem;
    }

    .msgList li {
        border: #c6c6c6 solid .0052rem;
        padding: .0521rem .1042rem .0521rem 1.0938rem;
        position: relative;
        font-size: .0729rem;
        margin-bottom: .0521rem;
        text-align: center;
        line-height: .2604rem;
    }

    li {
        list-style-position: outside;
        list-style-type: none;

    }

    .msgList li .msg {
        line-height: .1667rem;
        color: #999;
    }

    .content {
        padding-top: .1563rem;
        font-size: .0729rem;
        line-height: .125rem;
        color: #434343;

    }

    .tel {
        padding-top: .0521rem;
        font-size: .1875rem;
        color: #bb3944;
        font-family: Impact;
    }

    .txt {
        position: absolute;
        left: 0rem;
        top: 0rem;
        width: .9896rem;
        height: 100%;
        text-align: center;
        color: #FFFFFF;
        background: #2056c0;
        font-size: .0729rem;
        font-family: Arial, "微软雅黑";

    }

    .tittle {

        margin-top: .5208rem;
        height: .2083rem;
        text-align: center;
        line-height: .2083rem;
        font-weight: bold;
        font-size: .0833rem;
        color: #fff;

        position: relative;
    }
</style>