<template>
    <div>
        <div style="width: 6.5104rem;margin: 0 auto;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/product-center', query: { tab: 'third' }}">可变情报板系列</el-breadcrumb-item>
                <el-breadcrumb-item>隧道内可变信息情报板</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="display: flex;justify-content: center;margin-top: .4167rem;">
            <div>
                <img src="../../assets/qb4.webp" alt="" style="height: 2.3438rem;">

            </div>
            <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">

                <p class="name">可变限速标志
                </p>

                <P class="content" style="text-indent: .1198rem;font-size: .0833rem">
                    可变限速情报板是智能交通控制中心根据交通、天气及指挥调度部门的指令及时发布道路限速信息的设备，能够及时有效地向驾驶人员传递道路安全限速信息。可变限速情报板采用丰海自主研发的基于嵌入式工业控制计算机的控制系统，提供多种通信接口，具有高兼容性；同时，产品采用最新的智能控制驱动芯片和多电源热备份功能，智能性高，节能节电，使用寿命长。
                </P>

                <div style="position: absolute;bottom: .1563rem;">
                    <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
                        <icon class="el-icon-phone-outline"></icon>技术热线
                    </div>
                    <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>

                </div>


            </div>



        </div>

        <div class="title"
            style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
            详细参数
        </div>

        <!--  style="width: 6.5104rem;margin: 0 auto;"-->
        <div class="msgList" style="width: 6.5104rem;margin: 0 auto;">
            <ul>
                <!-- <li>
                    <div class="txt"><em>型号</em></div>
                    <div class="msg">IF-VS1600/IF-VS1200/IF-VS1000/IF-VS600</div>
                </li>
                <li>
                    <div class="txt"><em>像素点间距</em></div>
                    <div class="msg">10mm/16mm/20mm/25mm/31.25mm/33.33mm等</div>
                </li>
                <li>
                    <div class="txt"><em>安装方式/地点</em></div>
                    <div class="msg">单立柱、路侧/悬挂、隧道内/悬挂、隧道内/悬挂、隧道内/</div>
                </li>
                <li>
                    <div class="txt"><em>显示尺寸</em></div>
                    <div class="msg">1.6m*1.6m/1.2m*1.2m/1m*1m/0.6m*0.6m</div>
                </li>
                <li>
                    <div class="txt"><em>像素点阵</em></div>
                    <div class="msg">48*48/64*96/48*72/32*48</div>
                </li>
                <li>
                    <div class="txt"><em>中心显示尺寸</em></div>
                    <div class="msg">0.8m*0.8m/0.64m*0.69m/0.48m*0.72m/0.32m*0.48m</div>
                </li>
                <li>
                    <div class="txt"><em>最大功率</em></div>
                    <div class="msg">1.2KW/360W/300W/250W</div>
                </li>
                <li>
                    <div class="txt"><em>平均功率</em></div>
                    <div class="msg">0.36W/140W/120W/100W</div>
                </li> -->
                ::::
                <li>
                    <div class="txt"><em>点间距</em></div>
                    <div class="msg">10m、16m、20mm、25mm、31.25mm、33.33mm</div>
                </li>
                <li>
                    <div class="txt"><em>显示尺寸</em></div>
                    <div class="msg">1x1m 1.2X1.2m 1.4x1.4m等，可根据项目需求定制</div>
                </li>
                <li>
                    <div class="txt"><em>颜色配比</em></div>
                    <div class="msg">单色、双基色、全彩色任意可选</div>
                </li>

                <li>
                    <div class="txt"><em>可视距离</em></div>
                    <div class="msg">动态≥250m,静态≥200m</div>
                </li>
                <li>
                    <div class="txt"><em>输入电压</em></div>
                    <div class="msg">AC380V/220V±15%，50±2Hz</div>
                </li>
                <li>
                    <div class="txt"><em>发光亮度</em></div>
                    <div class="msg">全彩≥10000cd/㎡，双基色≥8000cd/㎡</div>
                </li>

                <li>
                    <div class="txt"><em>发光亮度</em></div>
                    <div class="msg">全彩≥10000cd/㎡，双基色≥8000cd/㎡</div>
                </li>



                <li>
                    <div class="txt"><em> 箱体材质</em></div>
                    <div class="msg">户外型烤漆钢板或铝含金材料可选，双层机箱、抗风、防富、防腐蚀、防坐等性能，防护等级达 IP66;</div>
                </li>
                <li>
                    <div class="txt"><em> LED平均寿命</em></div>
                    <div class="msg">≥10000小时</div>
                </li>
                <li>
                    <div class="txt"><em> MTBF MTTR</em></div>
                    <div class="msg">≥30000小时，≤0.5小时</div>
                </li>
                <li>
                    <div class="txt"><em>亮度调节</em></div>
                    <div class="msg">32级亮度控制，手动/自动/定时，可远程设置</div>
                </li>
                <li>
                    <div class="txt"><em>可视角度</em></div>
                    <div class="msg">≥30°</div>
                </li>
                <li>
                    <div class="txt"><em>抗风强度</em></div>
                    <div class="msg">≥40m/s</div>
                </li>
                <li>
                    <div class="txt"><em>刷新频率</em></div>
                    <div class="msg">≥100Hz</div>
                </li>
                <!-- <li>
                    <div class="txt"><em>像素失控率</em></div>
                    <div class="msg">＜0.1‰</div>
                </li> -->
                <li>
                    <div class="txt"><em>驱动方式</em></div>
                    <div class="msg">恒流驱动</div>
                </li>
                <!-- <li>
                    <div class="txt"><em>通讯接口与传输速度</em></div>
                    <div class="msg">RS232或RS485，速率1200；以太网10M/100M自适应</div>
                </li> -->
                <li>
                    <div class="txt"><em>工作温度、湿度</em></div>
                    <div class="msg">-40℃~+65℃、0~98%</div>
                </li>
            </ul>
        </div>
        <!--  -->



        <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
            <el-button @click="goBack">返回列表</el-button>
        </div>


    </div>




</template>
<script>


    export default {
        methods: {
            goBack() {
                this.$router.push({ path: '/product-center', query: { tab: 'third' } });
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            }
            ,

            //       goTo(){
            //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
            //     const windowHeight = window.innerHeight; // 
            //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
            //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
            //     window.scrollTo(0, scrollTo); //
            //   },
        }
    }




</script>

<style scoped>
    .name {
        padding-bottom: .1042rem;
        border-bottom: #eeeeee solid .0052rem;
        font-size: .1042rem;
        color: #2056c0;
        font-weight: bold;
        position: relative;
    }

    .msgList {
        padding-top: .2604rem;

        border-bottom: #eee solid .0052rem;
    }

    .msgList li {
        border: #c6c6c6 solid .0052rem;
        padding: .0521rem .1042rem .0521rem 1.0938rem;
        position: relative;
        font-size: .0729rem;
        margin-bottom: .0521rem;
        text-align: center;
        line-height: .2604rem;
    }

    li {
        list-style-position: outside;
        list-style-type: none;

    }

    .msgList li .msg {
        line-height: .1667rem;
        color: #999;
    }

    .content {
        padding-top: .1563rem;
        font-size: .0729rem;
        line-height: .125rem;
        color: #434343;

    }

    .tel {
        padding-top: .0521rem;
        font-size: .1875rem;
        color: #bb3944;
        font-family: Impact;
    }

    .txt {
        position: absolute;
        left: 0rem;
        top: 0rem;
        width: .9896rem;
        height: 100%;
        text-align: center;
        color: #FFFFFF;
        background: #2056c0;
        font-size: .0729rem;
        font-family: Arial, "微软雅黑";

    }

    .tittle {

        margin-top: .5208rem;
        height: .2083rem;
        text-align: center;
        line-height: .2083rem;
        font-weight: bold;
        font-size: .0833rem;
        color: #fff;

        position: relative;
    }
</style>