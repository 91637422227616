import { render, staticRenderFns } from "./ledDisplay4.vue?vue&type=template&id=6a0be512&scoped=true"
import script from "./ledDisplay4.vue?vue&type=script&lang=js"
export * from "./ledDisplay4.vue?vue&type=script&lang=js"
import style0 from "./ledDisplay4.vue?vue&type=style&index=0&id=6a0be512&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a0be512",
  null
  
)

export default component.exports