<template>

    <div>
    <div style="font-family: Arial, '微软雅黑';font-size:.0729rem; color: #999;padding-top: .1042rem;
    line-height: .125rem;">
      <P style="font-size: .125rem;width: 100%;flex-wrap: wrap;margin-top: .3125rem;
      color: #333;
      text-align: center;">广州开发区管委会秘书长陈建荣一行莅临公司参观指导
      </P>
  
      <p style="width: 100%;
      text-align: center;font-size: .0625rem;
      margin-top: .0781rem;">2016-07-12
      </p>
  

      <div style="width:6.5104rem;margin: 0 auto;padding-bottom: .0781rem;border-bottom: #eee solid .0052rem;">
      </div>

      <div style="width:6.5104rem;color: #666666;font-size: .0833rem;font-family: 'HarmonyOS_Sans_SC_Regular';margin: 0 auto;padding-top: .1302rem;padding-bottom: .6771rem;border-bottom: #eee solid .0052rem;position: relative;">
        
        <p style="text-indent: .1563rem;">
            2016年7月7日，广州开发管委会秘书长陈建荣一行多人到丰海科技参观指导，公司董事长胡亚平及财务总监向道庆接待了来访贵宾。
        </p>

        <p style="text-indent: .1563rem;">
            来访贵宾参观了丰海科技总部，听取了胡亚平董事长关于公司发展情况的工作汇报，详细了解了公司的核心技术及产品，对公司在智能交通领域取得的成绩给予了充分地肯定，并对公司未来的发展寄予厚望，希望公司不断创新，努力为智能交通信息化建设作出新的贡献。

          </p>
        <div style="position: absolute; bottom: 0;right: 0;">
       
          <div class="social-share" data-initialized="true">
            <span style="margin-right: .0521rem;">分享到:</span>
            <a href="http://www.weibo.com" class="social-share-icon icon-weibo"></a>
            <a href="#" class="social-share-icon icon-qq"> </a>
            <a href="#" class="social-share-icon icon-qzone"> </a>
            <a href="#" class="social-share-icon icon-wechat"> </a>
          </div>
       </div>
      </div>
      <div style="display: flex;justify-content: center; margin-top: .1302rem;margin-bottom: .1302rem;">
        <el-button @click="goBack">返回列表</el-button>
      </div>
    </div>
    </div>
  </template>
  
  <script>
    export default {
      methods: {
      goBack() {
        history.back();
      }
    }
  }
  </script>