<template>
    <div>
        <div style="width: 6.5104rem;margin: 0 auto;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/product-center', query: { tab: 'nine' }}">收费车道设备系列</el-breadcrumb-item>
                <el-breadcrumb-item>隧道车道指示标志</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="display: flex;justify-content: center;margin-top: .4167rem;">
            <div>
                <img src="../../assets/042.webp" alt="" style="height: 1.1198rem;">

            </div>
            <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
                <p class="name">隧道车道指示标志</p>
                <P class="content" style="text-indent: .1198rem;font-size: .0833rem">隧道车道指示标志是一种用于指示车辆行驶方向和安全通行的道路标志，主要用于隧道内部的车道划分和导航。隧道车道指示标志通过图案、箭头和文字等元素，向驾驶员提供准确的车道信息，帮助他们选择正确的行驶方向和车道位置；同时还包括其他重要信息，如限速、出口和紧急出口等，以提醒驾驶员注意安全驾驶和紧急情况。隧道车道指示标志有助于提高隧道内交通的流畅性和安全性，为驾驶员提供清晰指引，减少事故风险。
                </P>
                <div style="margin-top: .1563rem;">
                    <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
                        <icon class="el-icon-phone-outline"></icon>技术热线
                    </div>
                    <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>

                </div>


            </div>



        </div>

        <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
            详细参数
        </div>

        <div class="msgList" style="width: 6.5104rem;margin: 0 auto;">
            <ul>
                <li>
                    <div class="txt"><em>型号</em></div>
                    <div class="msg">IF-V220-AB(BB) / IF-V221-AB(BB)</div>
                </li>
                <li>
                    <div class="txt"><em>可视距离</em></div>
                    <div class="msg">≥200m</div>
                </li>
                <li>
                    <div class="txt"><em>可视角度</em></div>
                    <div class="msg">大于30°</div>
                </li>
                <li>
                    <div class="txt"><em>亮度调节</em></div>
                    <div class="msg">8档手动或自动可调</div>
                </li>

                <li>
                    <div class="txt"><em>显示尺寸</em></div>
                    <div class="msg">600mmx600mm , 510mm ×510mm等</div>
                </li>
                <li>
                    <div class="txt"><em>功率</em></div>
                    <div class="msg">25W</div>
                </li>

                <li>
                    <div class="txt"><em>单体显示组合</em></div>
                    <div class="msg">双面显示红色"x",绿色"⬇",车行横洞处增设绿色"⬅"</div>
                </li>
                <li>
                    <div class="txt"><em>防护等级</em></div>
                    <div class="msg">IP65</div>
                </li>
                <li>
                    <div class="txt"><em>工作环境</em></div>
                    <div class="msg">温度-40℃~70℃，湿度0%~98%</div>
                </li>

                <li>
                    <div class="txt"><em>控制方式</em></div>
                    <div class="msg">IO接口控制/开关量控制/RS485控制</div>
                </li>

                <li>
                    <div class="txt"><em>MTBF</em></div>
                    <div class="msg">≥30000小时</div>
                </li>

                <li>
                    <div class="txt"><em>MTTR</em></div>
                    <div class="msg">≤0.5小时</div>
                </li>
                
            </ul>
        </div>

      
            



        <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
            <el-button @click="goBack">返回列表</el-button>
        </div> 


    </div>




</template>
<script>


    export default {
        methods: {
            goBack() {
  this.$router.push({ path: '/product-center', query: { tab: 'nine' }});
  const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

}
    
        ,

            //       goTo(){
            //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
            //     const windowHeight = window.innerHeight; // 
            //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
            //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
            //     window.scrollTo(0, scrollTo); //
            //   },
        }
    }




</script>

<style scoped>
    .name {
        padding-bottom: .1042rem;
        border-bottom: #eeeeee solid .0052rem;
        font-size: .1042rem;
        color: #2056c0;
        font-weight: bold;
        position: relative;
    }

    .msgList {
        padding-top: .2604rem;

        border-bottom: #eee solid .0052rem;
    }

    .msgList li {
        border: #c6c6c6 solid .0052rem;
        padding: .0521rem .1042rem .0521rem 1.0938rem;
        position: relative;
        font-size: .0729rem;
        margin-bottom: .0521rem;
        text-align: center;
        line-height: .2604rem;
    }

    li {
        list-style-position: outside;
        list-style-type: none;

    }

    .msgList li .msg {
        line-height: .1667rem;
        color: #999;
    }

    .content {
        padding-top: .1563rem;
        font-size: .0729rem;
        line-height: .125rem;
        color: #434343;

    }

    .tel {
        padding-top: .0521rem;
        font-size: .1875rem;
        color: #bb3944;
        font-family: Impact;
    }

    .txt {
        position: absolute;
        left: 0rem;
        top: 0rem;
        width: .9896rem;
        height: 100%;
        text-align: center;
        color: #FFFFFF;
        background: #2056c0;
        font-size: .0729rem;
        font-family: Arial, "微软雅黑";

    }

    .tittle {

        margin-top: .5208rem;
        height: .2083rem;
        text-align: center;
        line-height: .2083rem;
        font-weight: bold;
        font-size: .0833rem;
        color: #fff;

        position: relative;
    }
</style>