<template>
  <div>
    <div
      style="width: 100%; height: .6823rem; display: flex; justify-content: center; align-items: center; position: relative;">
      <img style="margin: 0 auto;position: absolute;" src="../../assets/智慧高速.png" alt="">
      
      <p
        style="font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0938rem;">
        智慧高速
      </p>

      <p
        style=" display: inline-block; height: .0104rem; width: .3333rem;background-color:  #407BF5;;font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0417rem;">
      </p>
      
    </div>


    <div style="width: 6.5104rem;margin:0 auto;">
      <el-breadcrumb separator-class="el-icon-arrow-right"> 
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/SoluTion'}">解决方案</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/samCov18'}">智慧高速</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div style="display: flex;justify-content: center;margin-top: .4167rem;">
      <div>
        <img src="../../assets/fangpilao.webp" alt="" style="">
      </div>

      <div style="width:2.7344rem; margin-left: .2604rem; position: relative;">
        <img src="../../assets/fenghai2.webp" alt="" style="vertical-align: middle;margin-left: .0781rem;">
        <span
          style=" font-weight:bold;font-size: .1667rem; font-family: Fontquan-XinYiGuanHeiTi, Fontquan-XinYiGuanHeiTi; color: #407BF5;vertical-align: middle; margin-left: .125rem;">智能防疲劳安全预警系统</span>

        <p style="color:#333;margin-top: .2083rem; font-size: .0833rem;">
          当雷达检测模块检测到前方350米范围内来车时，立即向预警模块发出指令，启动声光预警，警灯闪烁、警笛长鸣、启动激光射灯，警示和提醒驾驶人安全驾驶、依法行车，尤其是对昏昏欲睡的驾驶人，警醒作用效果明显。</p>
          <div style="margin: 0 auto;position: absolute;bottom: .1042rem;right: 0;" class="chan">
            <ul style="list-style: none;display: flex;justify-content: flex-start;">
              <li @click="changeColor(0)" :style="{backgroundColor: activeIndex === 0 ? '#407BF5' : ''}">联系我们</li>
              <li @click="changeColor(1)" :style="{backgroundColor: activeIndex === 1 ? '#407BF5' : ''}">了解详情</li>
            </ul>
          </div>
      </div>

      <!-- font-family: 'HarmonyOS_Sans_SC_Regular'; -->



    </div>

    <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
      详细方案</div>

    <!--  style="width: 6.5104rem;margin: 0 auto;"-->
    <div class="msgList" style="width: 6.5104rem;margin: 0 auto;">
      <div class="xitong" >
       
        <p>系统布设</p>
      </div>
      <p style="margin-top: .0521rem;">每处门架设4套激光射灯，在不影响驾驶员视线的前提下形成激光光幕，覆盖范围可达1000m以上。在低能见度下(雾、夜)自动开启绿色射线，对车流进行防疲劳提醒，同时对驾驶员视觉产生冲击。激光射灯安装在门架顶部，安装角度根据现场情况适当调整，防止遮挡。</p>
      <div style="width: 100%;display: flex;justify-content: center;">
          <img src="../../assets/fangpilao2.webp" alt="" style="width:6.5104rem;">
      </div>
      
      <div class="xitong"  >
       
        <p >系统架构</p>
      </div>

      <div style="width: 100%;display: flex;justify-content: center;">
           <img src="../../assets/fangpilao3.webp" alt="" style="margin-top: .0521rem;height: 3.125rem;">
      </div>
     

    </div>

    <div style="width:6.5104rem;margin: 0 auto;padding-bottom: .0781rem;border-bottom: #eee solid .0052rem;">
    </div>


    <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
      <el-button @click="goBack">返回列表</el-button>
    </div>





  </div>




</template>


<script>

  export default {
    data(){
      return{
        activeIndex: 0,
        // activeName: 'first',
      }
    },
    
    methods: {
      goBack() {
        history.back();
      }
      ,

      changeColor(index) {
        this.activeIndex = index;
        if (index === 0) {
          this.$router.push({ path: 'joinGo' }); // 跳转到名为'join'的路由
        }
        if (index === 1) {
          window.open('/pptx/丰海科技-防疲劳预警.pdf', '_blank');
          
        }

      },
 

      //       goTo(){
      //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
      //     const windowHeight = window.innerHeight; // 
      //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
      //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
      //     window.scrollTo(0, scrollTo); //
      //   },
    }
  }




</script>

<style scoped>
  .name {

    padding-bottom: .1042rem;
    border-bottom: #eeeeee solid .0052rem;
    font-size: .1042rem;
    color: #2056c0;
    font-weight: bold;
    position: relative;

  }

  .msgList {
    padding-top: .2604rem;
    /* border-bottom: #eee solid .0052rem; */
  }

  .msgList li {
    border: #c6c6c6 solid .0052rem;
    padding: .0521rem .1042rem .0521rem 1.0938rem;
    position: relative;
    font-size: .0729rem;
    margin-bottom: .0521rem;
    text-align: center;
    line-height: .2604rem;
  }

  li {
    list-style-position: outside;
    list-style-type: none;
  }

  .msgList li .msg {
    line-height: .1667rem;
    color: #999;
  }

  .content {
    padding-top: .1563rem;
    font-size: .0729rem;
    line-height: .125rem;
    color: #434343;

  }

  .tel {
    padding-top: .0521rem;
    font-size: .1875rem;
    color: #bb3944;
    font-family: Impact;
  }

  .txt {
    position: absolute;
    left: 0rem;
    top: 0rem;
    width: .9896rem;
    height: 100%;
    text-align: center;
    color: #FFFFFF;
    background: #2056c0;
    font-size: .0729rem;
    font-family: Arial, "微软雅黑";

  }


  .tittle {
    margin-top: .5208rem;
    height: .2083rem;
    text-align: center;
    line-height: .2083rem;
    font-weight: bold;
    font-size: .0833rem;
    color: #fff;
    position: relative;
  }

  .chan li {
    width: 1.0417rem;
    height: .2083rem;
    text-align: center;
    line-height: .2083rem;
    font-weight: bold;
    background-color: #E1E1E1;
  }

  .xitong{
    background-image: url(../../assets/xitong.png);
    background-size: cover;
    width: 1.0938rem;
    height: .2344rem;
    transform: translateY(-0.1042rem);
    margin-top: .1563rem;
}

.xitong p {
  text-align: center;
  line-height: .2344rem;
  color: #407BF5;
  font-size: .1042rem;
  font-weight: bold;
  letter-spacing: .0521rem;

}



</style>