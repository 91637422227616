<template>
    <div>
        <div style="width: 6.5104rem;margin: 0 auto;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/product-center', query: { tab: 'third' }}">可变情报板系列</el-breadcrumb-item>
                <el-breadcrumb-item>可变情报板发布与控制软件</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="display: flex;justify-content: center;margin-top: .4167rem;">
            <div style="display: inline-block;transform: scale(1.05) translateX(-0.2604rem) translateY(0rem);">
                <img src="../../assets/028.webp" alt="">

            </div>
            <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
                <p class="name">可变情报板发布与控制软件</p>
                <P class="content" style="text-indent: .1198rem;font-size: .0833rem">可变情报板发布与控制软件是一种用于管理和控制可变情报板（VariableMessageSigns，简称VMS）的应用软件。它提供了一个集中管理的平台，可以实时发布、更新和控制VMS上显示的文字、图像和动态信息，以满足交通管理、公共安全和信息传达等需求。该软件具备用户友好的界面、灵活的内容编辑和调度功能，以及实时监控和远程控制的能力，为VMS的管理和运营提供了便捷和高效的解决方案。

                </P>
                <div style="margin-top: .1563rem;">
                    <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
                        <icon class="el-icon-phone-outline"></icon>技术热线
                    </div>
                    <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>

                </div>


            </div>



        </div>

        <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
            详细参数
        </div>

        <!--  style="width: 6.5104rem;margin: 0 auto;"-->
        <!-- <div class="msgList"  style="width: 6.5104rem;margin: 0 auto;">
            <ul> -->
                <!-- <li>
                    <div class="txt"><em>型号</em></div>
                    <div class="msg">IF-KXB-P33-0004A(D)/IF-KXB-P31-0008A(D)/IF-KXB-P25-0008B(C)/IF-KXB-P20-0008B(C)
                    </div>
                </li> -->
                <!-- <li>
                    <div class="txt"><em>像素点间距</em></div>
                    <div class="msg">33.33mm/31.25mm/25mm/20mm</div>
                </li>
                <li>
                    <div class="txt"><em>安装方式</em></div>
                    <div class="msg">单立柱/双立柱/双立柱/双立柱</div>
                </li>
                <li>
                    <div class="txt"><em>显示尺寸</em></div>
                    <div class="msg">1.6m1.6m/2m*4m/2m*4m/1.92m*3.84m</div>
                </li>
                <li>
                    <div class="txt"><em>整屏分辨率</em></div>
                    <div class="msg">48*48/64*128/80*160/96*192</div>
                </li>
                <li>
                    <div class="txt"><em>单元模块尺寸</em></div>
                    <div class="msg">1m*1m/0.8m*0.8m/1m*1m/0.96m*0.96m</div>
                </li>
                <li>
                    <div class="txt"><em>单元模块分辨率</em></div>
                    <div class="msg">24*24/32*32/40*40/48*48</div>
                </li>
                <li>
                    <div class="txt"><em>最大功率</em></div>
                    <div class="msg">1.2KW/2.3KW/2.4KW/4.6KW</div>
                </li>
                <li>
                    <div class="txt"><em>平均功率</em></div>
                    <div class="msg">0.36W/0.7KW/0.72KW/1.38KW</div>
                </li>
                <li>
                    <div class="txt"><em>亮度</em></div>
                    <div class="msg">≥8000cd/㎡</div>
                </li>
                <li>
                    <div class="txt"><em>亮度调节</em></div>
                    <div class="msg">32级亮度控制，手动/自动/定时，可远程设置</div>
                </li>
                <li>
                    <div class="txt"><em>可视角度</em></div>
                    <div class="msg">≥30°</div>
                </li>
                <li>
                    <div class="txt"><em>抗风强度</em></div>
                    <div class="msg">≥40m/s</div>
                </li>
                <li>
                    <div class="txt"><em>刷新频率</em></div>
                    <div class="msg">＞120Hz</div>
                </li>
                <li>
                    <div class="txt"><em>像素失控率</em></div>
                    <div class="msg">＜0.1‰</div>
                </li>
                <li>
                    <div class="txt"><em>驱动方式</em></div>
                    <div class="msg">静态恒流驱动</div>
                </li>
                <li>
                    <div class="txt"><em>通讯接口与传输速度</em></div>
                    <div class="msg">RS232或RS485，速率1200；以太网10M/100M自适应</div>
                </li>
                <li>
                    <div class="txt"><em>工作温度、湿度</em></div>
                    <div class="msg">-40℃~+65℃、0~98%</div>
                </li>
                <li>
                    <div class="txt"><em>接地电阻</em></div>
                    <div class="msg">工作接地电阻＜1欧姆，防雷接地＜4欧姆</div>
                </li>
            </ul>
        </div> -->
        <!--  -->



        <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
            <el-button @click="goBack">返回列表</el-button>
        </div>


    </div>




</template>
<script>


    export default {
        methods: {
            goBack() {
  this.$router.push({ path: '/product-center', query: { tab: 'third' }});
  const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

}
            ,

            //       goTo(){
            //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
            //     const windowHeight = window.innerHeight; // 
            //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
            //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
            //     window.scrollTo(0, scrollTo); //
            //   },
        }
    }




</script>

<style scoped>
    .name {
        padding-bottom: .1042rem;
        border-bottom: #eeeeee solid .0052rem;
        font-size: .1042rem;
        color: #2056c0;
        font-weight: bold;
        position: relative;
    }

    .msgList {
        padding-top: .2604rem;

        border-bottom: #eee solid .0052rem;
    }

    .msgList li {
        border: #c6c6c6 solid .0052rem;
        padding: .0521rem .1042rem .0521rem 1.0938rem;
        position: relative;
        font-size: .0729rem;
        margin-bottom: .0521rem;
        text-align: center;
        line-height: .2604rem;
    }

    li {
        list-style-position: outside;
        list-style-type: none;

    }

    .msgList li .msg {
        line-height: .1667rem;
        color: #999;
    }

    .content {
        padding-top: .1563rem;
        font-size: .0729rem;
        line-height: .125rem;
        color: #434343;

    }

    .tel {
        padding-top: .0521rem;
        font-size: .1875rem;
        color: #bb3944;
        font-family: Impact;
    }

    .txt {
        position: absolute;
        left: 0rem;
        top: 0rem;
        width: .9896rem;
        height: 100%;
        text-align: center;
        color: #FFFFFF;
        background: #2056c0;
        font-size: .0729rem;
        font-family: Arial, "微软雅黑";

    }

    .tittle {

        margin-top: .5208rem;
        height: .2083rem;
        text-align: center;
        line-height: .2083rem;
        font-weight: bold;
        font-size: .0833rem;
        color: #fff;

        position: relative;
    }
</style>